import * as React from 'react';
import useTranslate from 'hooks/useTranslate';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Select, { MultiValue, SingleValue } from 'react-select';
import styles from 'components/LookupSelect/LookupSelect.module.scss';

import { SurgeryType } from '@bondvet/types/surgeryReferrals';
import { sendSurgeryReferralContext } from '../../context';
import { SurgeonOption } from '../../types';

import formStyles from '../QuestionForm/QuestionForm.module.scss';

interface RecommendedSurgeonsProps {
    surgeonOptions: ReadonlyArray<SurgeonOption>;
    surgeryType?: SurgeryType | null;
}

export default function RecommendedSurgeons({
    surgeonOptions,
    surgeryType,
}: RecommendedSurgeonsProps): React.ReactElement {
    const translate = useTranslate();
    const { recommendedSurgeonIds, changeRecommendedSurgeonIds } =
        React.useContext(sendSurgeryReferralContext);

    const onChange = React.useCallback(
        (
            newOptions: SingleValue<SurgeonOption> | MultiValue<SurgeonOption>,
        ) => {
            if (surgeryType?.isSpecialtySurgery) {
                const selectedSurgeon =
                    newOptions as SingleValue<SurgeonOption>;
                if (selectedSurgeon?.value) {
                    changeRecommendedSurgeonIds([selectedSurgeon.value]);
                }
            } else {
                changeRecommendedSurgeonIds(
                    (newOptions as SurgeonOption[])?.map(
                        (option) => option.value,
                    ) || [],
                );
            }
        },
        [changeRecommendedSurgeonIds, surgeryType],
    );

    const values = React.useMemo<SurgeonOption[]>(() => {
        return recommendedSurgeonIds?.map((id) => ({
            value: id,
            label:
                surgeonOptions.find((option) => option.value === id)?.label ||
                '?',
        }));
    }, [recommendedSurgeonIds, surgeonOptions]);

    return (
        <fieldset className={formStyles.container}>
            <div className={formStyles.label}>
                {translate(
                    'vetspireExtension.surgeryReferrals.send.recommendedSurgeons',
                )}
            </div>
            <div>
                <Select
                    isMulti={!surgeryType?.isSpecialtySurgery}
                    name="surgeons"
                    options={surgeonOptions}
                    value={values}
                    className={styles.lookup}
                    classNamePrefix="lookup-select"
                    onChange={onChange}
                    placeholder={
                        <div className={styles.placeholder}>
                            <PersonOutlineIcon
                                fontSize="small"
                                classes={{
                                    root: styles.placeholderMuiIcon,
                                }}
                            />
                            {translate(
                                'vetspireExtension.surgeryReferrals.send.recommendedSurgeonsPlaceholder',
                            )}
                        </div>
                    }
                />
            </div>
        </fieldset>
    );
}
