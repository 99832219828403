import * as React from 'react';
import type {
    SurgeryType,
    PreferredClinic as PreferredClinicType,
} from '@bondvet/types/surgeryReferrals';
import classnames from 'classnames';
import styles from 'components/LookupSelect/LookupSelect.module.scss';
import useTranslate from 'hooks/useTranslate';
import Select, { SingleValue } from 'react-select';
import { SurgeryLocation } from '../../api/queries';
import { sendSurgeryReferralContext } from '../../context';
import formStyles from '../QuestionForm/QuestionForm.module.scss';

type PreferredClinicOption = {
    value: PreferredClinicType;
    label: string;
};

function getValue({ value }: PreferredClinicOption): PreferredClinicType {
    return value;
}

function getLabel({ label }: PreferredClinicOption): string {
    return label;
}

type PreferredClinicProps = {
    surgeryType?: SurgeryType | null;
    surgeryLocations: ReadonlyArray<SurgeryLocation>;
};

export default function PreferredClinic({
    surgeryType,
    surgeryLocations,
}: PreferredClinicProps): React.ReactElement {
    const translate = useTranslate();
    const { preferredClinic, changePreferredClinic } = React.useContext(
        sendSurgeryReferralContext,
    );

    const handleChange = React.useCallback(
        (option: SingleValue<PreferredClinicOption>) => {
            if (option) {
                changePreferredClinic(option.value);
            }
        },
        [changePreferredClinic],
    );

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    const options = React.useMemo<ReadonlyArray<PreferredClinicOption>>(
        () =>
            surgeryLocations
                .filter(
                    ({
                        showInSurgeryReferralsSelect,
                        _vetspire,
                        name,
                        performsSpecialtySurgeries,
                    }) =>
                        showInSurgeryReferralsSelect &&
                        (!surgeryType?.isSpecialtySurgery ||
                            performsSpecialtySurgeries) &&
                        !!_vetspire?.id &&
                        !!name,
                )
                .sort((a, b) => a.name!.localeCompare(b.name!))
                .map(({ _vetspire, name }) => ({
                    value: _vetspire!.id,
                    label: name!,
                })),
        [surgeryLocations, surgeryType],
    );

    React.useEffect(() => {
        if (options.length === 1) {
            handleChange(options[0]);
        }
    }, [handleChange, options]);

    const value = React.useMemo(
        () => options.find((option) => option.value === preferredClinic),
        [preferredClinic, options],
    );

    return (
        <fieldset className={formStyles.container}>
            <label htmlFor="preferred-clinic" className={formStyles.label}>
                {translate(
                    'vetspireExtension.surgeryReferrals.send.preferredClinic',
                )}
            </label>
            <Select
                name="preferred-clinic"
                id="preferred-clinic"
                options={options}
                value={value}
                onChange={handleChange}
                className={classnames(styles.lookup, styles.clinicLookup)}
                classNamePrefix="lookup-select"
                getOptionLabel={getLabel}
                getOptionValue={getValue}
            />
        </fieldset>
    );
}
