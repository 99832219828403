import * as React from 'react';
import { startOfDay, subHours, subSeconds } from 'date-fns';
import { CollectionReference } from '@firebase/firestore';

import { DIGITAL_WALK_INS_COLLECTION_NAME } from '@bondvet/types/digitalWalkIns/constants';
import { TEXT_MESSAGING_UNREAD_MESSAGE_EVENTS_FIREBASE_COLLECTION_NAME } from '@bondvet/types/textMessaging';
import {
    addBackgroundMessageListener,
    BackgroundMessage,
    MESSAGE_SOURCE_NAVIGATION,
    MESSAGE_TARGET_NAVIGATION,
} from 'lib/backgroundMessages';
import NavigationButton from 'components/NavigationButton/NavigationButton';
import navBond from 'assets/navBond.png';
import {
    closeFlyout,
    extendMenuBar,
    minimizeMenuBar,
    openFlyout,
    openToast,
    Page,
    sendDigitalWalkInsDataToSchedule,
} from 'lib/vetspireActions';
import { useAnalytics } from 'hooks/useAnalytics';
import { ReactComponent as ExpandArrowIcon } from 'assets/expandArrow.svg';
import classnames from 'classnames';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useMutation } from '@apollo/client';
import { setExtensionMenuBarItems } from 'api/bond/mutations';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import useGlobalEffects from 'hooks/useGlobalEffects';
import useViewerSettings from 'hooks/useViewerSettings';
import { SukiMode } from 'pages/Suki/types';
import { onSnapshot, query, QueryConstraint, where } from 'firebase/firestore';
import useLocationId from 'hooks/useLocationId';
import useFirebaseContext from 'hooks/firebase/useFirebaseContext';

import useMenuList, { MenuItem } from './hooks/useMenuList';
import NavigationItemsNotInEditMode from './components/NavigationItemsNotInEditMode';
import NavigationItemsInEditMode from './components/NavigationItemsInEditMode';

import styles from './NavigationBar.module.scss';
import useConversationalTextMessagingSettings from '../Texting/hooks/useConversationalTextMessagingSettings';
import { TEXTING_SELECTED_CLIENT } from '../Texting/Texting';
import useBondQuery from '../../hooks/useBondQuery';
import {
    DigitalWalkInsEnabledQueryResult,
    digitalWalkInsEnabledQuery,
    DigitalWalkInsEnabledQueryVariables,
} from '../DigitalWalkIns/api';

const TOASTED_TEXTING_CLIENT_TIMEOUT_SECONDS = 20;

const NavBond: React.FunctionComponent = () => (
    <img className={styles.logo} src={navBond} alt="Bond Vet" />
);

export default function NavigationBar(): React.ReactElement {
    const analytics = useAnalytics();
    const viewerSettings = useViewerSettings();
    const locationId = useLocationId();
    const textingSettings = useConversationalTextMessagingSettings();
    const { getCollection } = useFirebaseContext();

    // the nav bar is the only route that's always mounted (even if
    // the navbar is hidden) so we can use it to trigger global effects
    useGlobalEffects();

    const toastedClientTracking = React.useRef<
        { clientId: string; timestamp: Date }[]
    >([]);

    const [unreadMessageCount, setUnreadMessageCount] = React.useState(0);
    const [digitalWalkInsCount, setDigitalWalkInsCount] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState<string | null>();
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const [editMode, setEditMode] = React.useState<{
        on: boolean;
        menuButtons: MenuItem[];
    }>({ on: false, menuButtons: [] });
    const [sukiMode, setSukiMode] = React.useState<SukiMode>('idle');

    const extensionVersion = React.useMemo(() => {
        const manifestDataS = sessionStorage.getItem('manifestData');

        if (!manifestDataS) {
            return '?';
        }

        try {
            const manifestData = JSON.parse(manifestDataS);

            return (manifestData?.version as string | undefined) ?? '';
        } catch {
            return '?';
        }
    }, []);

    const onNavigationClick = React.useCallback(
        (page: Page): void => {
            if (currentPage === page) {
                closeFlyout();
                setCurrentPage(null);
                analytics.trackEvent(page, 'close_flyout');
            } else {
                openFlyout(page);
                setCurrentPage(page);
                analytics.trackEvent(page, 'open_flyout');
            }
        },
        [analytics, currentPage],
    );

    // catch 'closeFlyout' message to remove highlight from nav-button
    // and 'openPage' messages to set the highlight on the nav-button
    const messageListener = React.useCallback(
        (message: BackgroundMessage) => {
            switch (message.action) {
                case 'flyoutClosed':
                    if (currentPage !== null) {
                        setCurrentPage(null);
                    }
                    break;
                case 'openPage':
                    onNavigationClick(message.page as Page);
                    break;
                case 'setSukiMode':
                    setSukiMode((message.sukiMode as SukiMode) ?? 'idle');
                    break;
                case 'openTexting':
                    // Set the selected texting client to this client
                    if (message?.clientId) {
                        sessionStorage.setItem(
                            TEXTING_SELECTED_CLIENT,
                            message.clientId as string,
                        );
                    }

                    // Open texting if it's not already open
                    if (currentPage !== Page.texting) {
                        onNavigationClick(Page.texting);
                    }
                    break;
                case 'openQuestionnaire':
                    if (currentPage !== Page.questionnaire) {
                        onNavigationClick(Page.questionnaire);
                    }
                    break;
                default:
                    break;
            }
        },
        [currentPage, onNavigationClick],
    );

    React.useEffect(() => {
        return addBackgroundMessageListener(
            MESSAGE_SOURCE_NAVIGATION,
            messageListener,
        );
    }, [messageListener]);

    React.useEffect(() => {
        if (expanded) {
            extendMenuBar(true);
        } else {
            minimizeMenuBar();
        }
    }, [expanded]);

    // Reset and fetch digital walk ins on location change
    React.useEffect(() => {
        setDigitalWalkInsCount(0);
    }, [locationId]);

    const [menuButtons, setMenuButtons] = useMenuList(sukiMode);

    const [mutate, { loading, error }] = useMutation(setExtensionMenuBarItems, {
        context: { clientName: GRAPHQL_CLIENT_NAMES.default },
    });

    const [unreadMessageEventsCollection, setUnreadMessageEventsCollection] =
        React.useState<CollectionReference | null>(null);

    React.useEffect(() => {
        getCollection(
            TEXT_MESSAGING_UNREAD_MESSAGE_EVENTS_FIREBASE_COLLECTION_NAME,
        ).then((c) => {
            setUnreadMessageEventsCollection(c);
        });
    }, [getCollection]);

    // UNREAD MESSAGE BADGE COUNT LISTENER
    React.useEffect(() => {
        let unsubscribe = () => {};

        // Only listen if this user has access to texting...
        if (
            unreadMessageEventsCollection &&
            locationId &&
            menuButtons.find((button) => button.page === Page.texting)
        ) {
            const queryConstraints: QueryConstraint[] = [
                where('vetspireLocationId', '==', locationId),
                where('unread', '==', true),
                where(
                    'lastSeenAt',
                    '>=',
                    subHours(
                        new Date(),
                        textingSettings?.conversationalTextingSettings
                            ?.showMessagesForHours || 36,
                    ),
                ),
            ];

            const sessionQuery = query(
                unreadMessageEventsCollection,
                ...queryConstraints,
            );

            unsubscribe = onSnapshot(sessionQuery, (snapshot) => {
                if (snapshot.size !== unreadMessageCount) {
                    setUnreadMessageCount(snapshot.size);
                }
            });
        }
        return unsubscribe;
    }, [
        unreadMessageEventsCollection,
        unreadMessageCount,
        setUnreadMessageCount,
        locationId,
        getCollection,
        menuButtons,
        textingSettings,
    ]);

    // UNREAD MESSAGE TOAST LISTENER
    React.useEffect(() => {
        // Only listen if this user has access to texting and locationId is loaded...
        if (
            unreadMessageEventsCollection &&
            locationId &&
            menuButtons.find((button) => button.page === Page.texting)
        ) {
            const queryConstraints: QueryConstraint[] = [
                where('vetspireLocationId', '==', locationId),
                where('unread', '==', true),
                where('origin', '==', 'newMessage'),
                where('lastSeenAt', '>=', subSeconds(new Date(), 15)),
            ];

            const sessionQuery = query(
                unreadMessageEventsCollection,
                ...queryConstraints,
            );

            return onSnapshot(sessionQuery, (snapshot) => {
                if (snapshot.size > 0) {
                    for (const doc of snapshot.docs) {
                        const clientId = doc.get('vetspireClientId');

                        const toast = {
                            title: doc.get('clientName'),
                            timeoutMs: 10000,
                            text: 'New Message(s)',
                            category: 'newSmsMessage',
                            icon: 'texting',
                            replaceExisting: false,
                            onClickMessage: {
                                action: 'openTexting',
                                targets: [MESSAGE_TARGET_NAVIGATION],
                                data: {
                                    clientId,
                                },
                            },
                        };

                        const clientToastInfo =
                            toastedClientTracking.current.find(
                                (info) => info.clientId === clientId,
                            );

                        // If client has a tracked toast, check the timestamp to determine if should re-toast
                        if (clientToastInfo) {
                            if (
                                subSeconds(
                                    new Date(),
                                    TOASTED_TEXTING_CLIENT_TIMEOUT_SECONDS,
                                ) > clientToastInfo?.timestamp
                            ) {
                                clientToastInfo.timestamp = new Date();

                                openToast(toast);
                            }
                        } else {
                            toastedClientTracking.current = [
                                ...toastedClientTracking.current,
                                {
                                    clientId,
                                    timestamp: new Date(),
                                },
                            ];

                            // Open toast if this client hasn't already had a tracked toast
                            openToast(toast);
                        }
                    }
                }
            });
        }
        return () => {};
    }, [
        unreadMessageEventsCollection,
        locationId,
        toastedClientTracking,
        menuButtons,
    ]);

    const { data: digitalWalkInsEnabledData } = useBondQuery<
        DigitalWalkInsEnabledQueryResult,
        DigitalWalkInsEnabledQueryVariables
    >(digitalWalkInsEnabledQuery, {
        variables: { vetspireLocationId: locationId },
        fetchPolicy: 'cache-and-network',
    });

    const [digitalWalkInsCollection, setDigitalWalkInsCollection] =
        React.useState<CollectionReference | null>(null);

    React.useEffect(() => {
        getCollection(DIGITAL_WALK_INS_COLLECTION_NAME).then((c) => {
            setDigitalWalkInsCollection(c);
        });
    }, [getCollection]);

    // Digital Walk-Ins badge count listener (for Schedule and Nav bar)
    React.useEffect(() => {
        let unsubscribe = () => {};

        if (digitalWalkInsCollection) {
            if (
                digitalWalkInsEnabledData &&
                digitalWalkInsEnabledData?.digitalWalkInsEnabled
            ) {
                // Today's walk-ins at this location that haven't arrived or no showed yet
                const queryConstraints: QueryConstraint[] = [
                    where('vetspireLocationId', '==', locationId),
                    where('date', '>=', startOfDay(new Date())),
                    where('hasArrived', '==', false),
                    where('isNoShow', '==', false),
                    where('isDeleted', '==', false),
                ];

                const sessionQuery = query(
                    digitalWalkInsCollection,
                    ...queryConstraints,
                );

                unsubscribe = onSnapshot(sessionQuery, (snapshot) => {
                    setDigitalWalkInsCount(snapshot.size);
                    sendDigitalWalkInsDataToSchedule(snapshot.size);
                });
            } else {
                sendDigitalWalkInsDataToSchedule(null);
            }
        }
        return unsubscribe;
    }, [
        digitalWalkInsEnabledData,
        digitalWalkInsCount,
        digitalWalkInsCollection,
        locationId,
        setDigitalWalkInsCount,
    ]);

    if (error) {
        throw error;
    }

    return (
        <>
            <div
                className={classnames(
                    styles.container,
                    expanded ? styles.expanded : null,
                )}
            >
                <div className={styles.extensionVersion}>
                    {extensionVersion}
                </div>
                <div className={styles.menuItemsContainer}>
                    <NavigationButton
                        expanded={expanded}
                        icon={<NavBond />}
                        active={false}
                        noOpacity
                        onClick={() => {
                            analytics.trackEvent('navigation', 'close_flyout');
                            closeFlyout();
                            setCurrentPage(null);
                        }}
                    />

                    {editMode.on && (
                        <NavigationItemsInEditMode
                            menuButtons={menuButtons}
                            currentPage={currentPage}
                            expanded={expanded}
                            setMenuButtons={setMenuButtons}
                        />
                    )}

                    {!editMode.on && (
                        <NavigationItemsNotInEditMode
                            menuButtons={menuButtons}
                            currentPage={currentPage}
                            expanded={expanded}
                            onNavigationClick={onNavigationClick}
                            unreadMessageCount={unreadMessageCount}
                            digitalWalkInsCount={digitalWalkInsCount}
                        />
                    )}
                </div>

                {!editMode.on && (
                    <button
                        type="button"
                        className={styles.expandButton}
                        onClick={() => {
                            setExpanded(!expanded);
                        }}
                    >
                        <ExpandArrowIcon
                            className={classnames(
                                styles.icon,
                                expanded ? styles.reversedIcon : null,
                            )}
                        />
                    </button>
                )}

                {expanded && !editMode.on && (
                    <button
                        type="button"
                        className={styles.editButton}
                        onClick={() =>
                            setEditMode({
                                on: true,
                                menuButtons,
                            })
                        }
                    >
                        <EditOutlinedIcon className={styles.icon} />
                    </button>
                )}
                {editMode.on && (
                    <div className={styles.buttonGroup}>
                        <button
                            type="button"
                            className={styles.cancel}
                            disabled={loading}
                            onClick={() => {
                                setMenuButtons(editMode.menuButtons);
                                setEditMode({
                                    on: false,
                                    menuButtons: [],
                                });
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            disabled={loading}
                            type="button"
                            className={styles.save}
                            onClick={async () => {
                                await mutate({
                                    variables: {
                                        userId: viewerSettings.viewer?.id,
                                        menuItemList: menuButtons.map(
                                            (elem) => elem.page,
                                        ),
                                    },
                                });
                                setEditMode({
                                    on: false,
                                    menuButtons: [],
                                });
                            }}
                        >
                            Save
                        </button>
                    </div>
                )}
            </div>
        </>
    );
}
