import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslate } from '@bondvet/web-app-i18n/util';
import { format } from 'date-fns';

import { ReactComponent as SuccessSVG } from 'assets/success.svg';
import { ReactComponent as ReasonSVG } from 'assets/reason.svg';
import { ReactComponent as PatientSVG } from 'assets/patient.svg';
import { ReactComponent as StartSVG } from 'assets/start.svg';
import { ReactComponent as ProviderSVG } from 'assets/provider.svg';
import { ReactComponent as LocationSVG } from 'assets/locationMarker.svg';
import styles from './ConsultationSentDialog.module.scss';

type ConsultationSentDialogProps = {
    pet: string;
    appointment: string;
    date: string;
    provider: string;
    location: string;
    onClose: () => void;
};

export default function ConsultationSentDialog({
    pet,
    appointment,
    date,
    provider,
    location,
    onClose,
}: ConsultationSentDialogProps): React.ReactElement {
    const translate = useTranslate();

    return (
        <Dialog open classes={{ root: styles.root, paper: styles.paper }}>
            <DialogTitle className={styles.titleWrapper}>
                <SuccessSVG />
                <Typography className={styles.title}>
                    {translate(
                        'vetspireExtension.surgeryReferrals.scheduleConsultation.success.title',
                    )}
                </Typography>
            </DialogTitle>
            <DialogContent className={styles.content}>
                <div className={styles.title}>
                    {translate(
                        'vetspireExtension.surgeryReferrals.scheduleConsultation.success.details',
                    )}
                </div>
                <ul className={styles.detailsList}>
                    <li>
                        <PatientSVG />
                        <Typography>{pet}</Typography>
                    </li>
                    <li>
                        <ReasonSVG />
                        <Typography>{`${appointment} ${translate(
                            'vetspireExtension.surgeryReferrals.scheduleConsultation.success.appointmentPostfix',
                        )}`}</Typography>
                    </li>
                    <li>
                        <StartSVG />
                        <Typography>
                            {format(date, "EEEE, MMMM d, yyyy 'at' hh:mm a")}
                        </Typography>
                    </li>
                    <li>
                        <ProviderSVG />
                        <Typography>{provider}</Typography>
                    </li>
                    <li>
                        <LocationSVG />
                        <Typography>{`${translate(
                            'vetspireExtension.surgeryReferrals.scheduleConsultation.success.locationPrefix',
                        )}${location}`}</Typography>
                    </li>
                </ul>
            </DialogContent>
            <DialogActions>
                <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    className={styles.closeButton}
                    onClick={onClose}
                >
                    {translate(
                        'vetspireExtension.surgeryReferrals.scheduleConsultation.success.close',
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
