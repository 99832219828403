import * as React from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { eachLimit } from 'async';
import { ProviderRightValue } from '@bondvet/types/providers';
import useVetspireMutation from 'hooks/useVetspireMutation';
import useViewerSettings from 'hooks/useViewerSettings';
import useBondMutation from 'hooks/useBondMutation';
import { startOfMonth } from 'date-fns/startOfMonth';
import { endOfDay } from 'date-fns/endOfDay';
import type { Provider } from 'api/providers/queries';
import { OperationResult } from '@bondvet/types';
import { LogBulkSignedEncounterArguments } from '@bondvet/types/encounters';
import { Encounter } from 'api/encounters/queries';
import { parseISO } from 'date-fns';

type UnsignedEncountersVariables = {
    providerId: string;
    offset: number;
    startBefore?: Date;
    startAfter?: Date;
};

type UnsignedEncountersResult = {
    encounters: ReadonlyArray<Encounter>;
};

const unsignedEncountersQuery = gql`
    query encounters(
        $providerId: ID!
        $offset: Int
        $startBefore: NativeDateTime
        $startAfter: NativeDateTime
    ) {
        encounters(
            signed: false
            limit: 250
            providerId: $providerId
            offset: $offset
            startBefore: $startBefore
            startAfter: $startAfter
        ) {
            id
            start
            patient {
                id
                client {
                    id
                }
            }
            provider {
                id
                name
            }
        }
    }
`;

type SignEncounterVariables = {
    id: string;
};

type SignEncounterResult = {
    signEncounter: {
        id: string;
        signed: boolean;
        signedDatetime: string;
    };
};

const signEncounterMutation = gql`
    mutation signEncounter($id: ID!) {
        signEncounter(id: $id) {
            id
            signed
            signedDatetime
        }
    }
`;

const signEncounterLogMutation = gql`
    mutation logBulkSignedEncounter($encounter: BulkSignedEncounterLog!) {
        logBulkSignedEncounter(encounter: $encounter) {
            success
            error
        }
    }
`;

type FormValues = {
    start: Date | null;
    end: Date | null;
    signAll: boolean;
    teamMember: Provider[];
};

export default function useBulkSignEncounters() {
    const [error, setError] = React.useState<string | null>(null);
    const [loading, setLoading] = React.useState(false);
    const viewerSettings = useViewerSettings();

    const canOnlySignOwn = React.useMemo(() => {
        return (
            viewerSettings?.rights?.vetspireExtension_encounters ===
            ProviderRightValue.enabled_ownRecords
        );
    }, [viewerSettings?.rights?.vetspireExtension_encounters]);

    const [formValues, setFormValues] = React.useState<FormValues>({
        signAll: false,
        teamMember: [],
        start: startOfMonth(new Date()),
        end: endOfDay(new Date()),
    });

    const editFormValues = React.useCallback(
        (newValue: Partial<FormValues>) => {
            setFormValues((prev) => ({
                ...prev,
                ...newValue,
            }));
        },
        [],
    );

    const providerId: string | null | undefined = React.useMemo(() => {
        if (canOnlySignOwn) {
            return viewerSettings.viewer?.id;
        }
        if (formValues.teamMember.length === 1) {
            return formValues.teamMember[0].id;
        }
        return null;
    }, [canOnlySignOwn, formValues.teamMember, viewerSettings.viewer?.id]);

    const [runLazy] = useLazyQuery<
        UnsignedEncountersResult,
        UnsignedEncountersVariables
    >(unsignedEncountersQuery, {
        fetchPolicy: 'no-cache',
    });

    const [encountersToSign, setEncountersToSign] = React.useState<
        Encounter[] | undefined
    >(undefined);
    const [numberOfEncountersLoading, setNumberOfEncountersLoading] =
        React.useState<boolean>(false);

    React.useEffect(() => {
        const loadEncounters = async () => {
            if (providerId) {
                setNumberOfEncountersLoading(true);
                let offset = 0;
                let hasMore = true;
                const encounters = [];
                while (hasMore) {
                    const { data } = await runLazy({
                        variables: {
                            startBefore:
                                formValues.end && !formValues.signAll
                                    ? formValues.end
                                    : undefined,
                            startAfter:
                                formValues.start && !formValues.signAll
                                    ? formValues.start
                                    : undefined,
                            providerId: providerId as string,
                            offset,
                        },
                    });
                    if (!data?.encounters.length) {
                        hasMore = false;
                    } else {
                        encounters.push(...data.encounters);
                        offset = encounters.length;
                    }
                }
                setEncountersToSign(encounters);
                setNumberOfEncountersLoading(false);
            } else {
                setEncountersToSign(undefined);
            }
        };

        loadEncounters().then();
    }, [formValues, providerId, runLazy]);

    const [runSignEncounter] = useVetspireMutation<
        SignEncounterResult,
        SignEncounterVariables
    >(signEncounterMutation);

    const [runSignEncounterLog] = useBondMutation<
        OperationResult,
        LogBulkSignedEncounterArguments
    >(signEncounterLogMutation);

    const bulkSignEncounters = React.useCallback(async () => {
        if (providerId) {
            let success = true;
            setError(null);
            setLoading(true);

            await eachLimit(encountersToSign ?? [], 10, async (encounter) => {
                const { data: encounterData } = await runSignEncounter({
                    variables: {
                        id: encounter.id,
                    },
                });

                if (!encounterData?.signEncounter?.signed) {
                    setError('Not all encounters could be signed');
                    success = false;
                }

                const { errors } = await runSignEncounterLog({
                    variables: {
                        encounter: {
                            _id: encounter.id,
                            encounterStart: new Date(encounter.start),
                            clientId: encounter.patient?.client.id ?? '',
                            patientId: encounter.patient?.id ?? '',
                            providerId: encounter.provider?.id ?? '',
                            providerName: encounter.provider?.name ?? '',
                            signedAt: encounterData?.signEncounter
                                .signedDatetime
                                ? parseISO(
                                      encounterData?.signEncounter
                                          .signedDatetime,
                                  )
                                : new Date(),
                            signedByProviderName:
                                viewerSettings.viewer?.name ?? '',
                            signedByProviderId: viewerSettings.viewer?.id ?? '',
                        },
                    },
                });

                if (errors) {
                    setError(`Error Logging encounter #${encounter.id}`);
                    success = false;
                }
            });

            setLoading(false);
            return success;
        }
        return false;
    }, [
        providerId,
        encountersToSign,
        runSignEncounter,
        runSignEncounterLog,
        viewerSettings,
    ]);

    return {
        canOnlySignOwn,
        formValues,
        editFormValues,
        bulkSignEncounters,
        loading: loading || numberOfEncountersLoading,
        error,
        signAmount: !numberOfEncountersLoading
            ? encountersToSign?.length
            : undefined,
    };
}
