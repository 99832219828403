import * as React from 'react';
import {
    format,
    isSameMonth,
    addDays,
    isBefore,
    startOfDay,
    isSameDay,
} from 'date-fns';
import classnames from 'classnames';
import Tooltip from '@mui/material/Tooltip';

import WeekSelect from 'components/WeekSelect';
import styles from './WeekCalendar.module.scss';

type WeekCalendarProps = {
    week: Date;
    setWeek: (week: Date) => void;
    disablePast?: boolean;
    selectedDay: Date | null;
    onSelectDay: (day: Date) => void;
    checkDayDisabled?: (date: Date) => boolean;
    disabledDayTooltipText?: string;
};

export default function WeekCalendar({
    week,
    setWeek,
    disablePast,
    selectedDay,
    onSelectDay,
    checkDayDisabled,
    disabledDayTooltipText,
}: WeekCalendarProps): React.ReactElement {
    const sameMonth = React.useMemo(
        () => isSameMonth(week, addDays(week, 6)),
        [week],
    );

    const dayLabels: JSX.Element[] = ['S', 'M', 'T', 'W', 'T', 'F', 'S'].map(
        (day, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`day.${index}`}>
                <div className={styles.dayLabel}>{day}</div>
            </li>
        ),
    );

    const days = React.useMemo(() => {
        return Array.from({ length: 7 }, (_, index) => index).map((index) => {
            const date = addDays(week, index);
            const isPast = isBefore(date, startOfDay(new Date()));
            let dayDisabled = false;
            if (disablePast && isPast) {
                dayDisabled = true;
            } else {
                dayDisabled = checkDayDisabled?.(date) ?? false;
            }
            const isSelected = !!selectedDay && isSameDay(date, selectedDay);

            return (
                <li
                    key={`day.${date.toISOString()}`}
                    className={classnames(styles.dayWrapper, {
                        [styles.selected]: isSelected,
                    })}
                >
                    <Tooltip
                        title={
                            dayDisabled && !isPast ? disabledDayTooltipText : ''
                        }
                    >
                        <button
                            className={classnames(styles.dayButton, {
                                [styles.disabled]: dayDisabled,
                            })}
                            onClick={(e) => {
                                e.preventDefault();
                                onSelectDay(date);
                            }}
                            disabled={dayDisabled}
                        >
                            {format(date, 'd')}
                        </button>
                    </Tooltip>
                </li>
            );
        });
    }, [
        checkDayDisabled,
        disablePast,
        disabledDayTooltipText,
        onSelectDay,
        selectedDay,
        week,
    ]);

    return (
        <div className={styles.calendar}>
            <WeekSelect
                disablePast={disablePast}
                week={week}
                onChange={setWeek}
                weekStartFormat="MMMM d"
                weekEndFormat={sameMonth ? 'd' : 'MMMM d'}
            />
            <ul className={styles.datesList}>{dayLabels}</ul>
            <ul className={styles.datesList}>{days}</ul>
        </div>
    );
}
