import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import classnames from 'classnames';
import type { CreditCard } from 'api/types';
import CreditCardBrand from 'components/CreditCards/components/CreditCardBrand';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Chip from '@mui/material/Chip';
import DialogContentText from '@mui/material/DialogContentText';
import useTranslate from 'hooks/useTranslate';
import {
    AppointmentFeeProductType,
    FEE_PRODUCT_TYPE_NO_SHOW,
} from '@bondvet/types/noShows';
import { useAnalytics } from 'hooks/useAnalytics';
import { Page } from 'lib/vetspireActions';
import styles from './Card.module.scss';
import ChargeFeeProduct from '../ChargeFeeProduct';

export type CardProps = {
    classes?: Partial<typeof styles>;
    card: CreditCard;
    onDelete?: () => void;
    onSetDefault?: () => void;
    chargeNoShowFees?: boolean;
    clientHasPatientsWithActivePlans?: boolean | null;
};

function Card({
    classes,
    card: { brand, expirationMonth, expirationYear, last4, isDefault },
    onDelete,
    onSetDefault,
    chargeNoShowFees = false,
    clientHasPatientsWithActivePlans,
}: CardProps): React.ReactElement {
    const analytics = useAnalytics();
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [showFeeModal, setShowFeeModal] =
        React.useState<AppointmentFeeProductType | null>(null);
    const [menuAnchorEl, setMenuAnchorEl] =
        React.useState<HTMLButtonElement | null>(null);

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        const target = event.currentTarget;
        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                setMenuAnchorEl(target);
            });
        });
    };

    const closeMenu = () => {
        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                setMenuAnchorEl(null);
            });
        });
    };

    const handleDelete = (event: React.SyntheticEvent) => {
        if (isDefault && clientHasPatientsWithActivePlans) {
            return;
        }

        event.preventDefault();

        closeMenu();

        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                setShowDeleteModal(true);
            });
        });
    };

    const handleFeeProduct = (
        event: React.SyntheticEvent,
        feeProductType: AppointmentFeeProductType,
    ) => {
        event.preventDefault();
        closeMenu();

        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                setShowFeeModal(feeProductType);
            });
        });
    };

    const closeDeleteModal = () => {
        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                setShowDeleteModal(false);
            });
        });
    };

    const handleSubmitDelete = React.useCallback(
        (event: React.SyntheticEvent) => {
            event.preventDefault();
            closeDeleteModal();
            onDelete?.();
            analytics.trackEvent(Page.clientDetails, 'delete_credit_card');
        },
        [onDelete, analytics],
    );

    const handleCancelDelete = (event: React.SyntheticEvent) => {
        event.preventDefault();
        closeDeleteModal();
    };

    const closeFeeProductModal = () => {
        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                setShowFeeModal(null);
            });
        });
    };

    const handleSetDefaultCard = (event: React.SyntheticEvent) => {
        event.preventDefault();
        closeMenu();

        onSetDefault?.();
    };

    const label = (
        <>
            {last4 && `**** ${last4}`}
            {expirationYear &&
                expirationMonth &&
                ` (${expirationMonth}/${expirationYear})`}
        </>
    );

    const translate = useTranslate();

    const addChargeNoShowFeesOptions = isDefault && chargeNoShowFees;
    const hideMenu = !onDelete && !onSetDefault && !addChargeNoShowFeesOptions;

    return (
        <>
            <div className={classnames(classes?.root, styles.root)}>
                <div className={classnames(classes?.info, styles.info)}>
                    <CreditCardBrand className={styles.logo} brand={brand} />
                    <p>{label}</p>
                    {isDefault && (
                        <Chip
                            color="primary"
                            label={translate(
                                'vetspireExtension.clientDetails.payments.creditCards.isDefault',
                            )}
                            size="small"
                            classes={{
                                root: styles.chip,
                                sizeSmall: styles.smallChip,
                            }}
                        />
                    )}
                </div>
                {hideMenu || (
                    <IconButton
                        classes={{ root: styles.delete }}
                        onClick={handleMenuClick}
                        type="button"
                        aria-controls="actions-menu"
                        size="large"
                    >
                        <MoreHorizIcon color="inherit" />
                    </IconButton>
                )}
            </div>

            {hideMenu || (
                <Menu
                    id="actions-menu"
                    anchorEl={menuAnchorEl}
                    keepMounted
                    open={Boolean(menuAnchorEl)}
                    onClose={closeMenu}
                    classes={{ list: styles.menu }}
                >
                    {onSetDefault && (
                        <MenuItem
                            dense
                            classes={{ root: styles.menuItem }}
                            onClick={handleSetDefaultCard}
                            disabled={isDefault}
                        >
                            {translate(
                                'vetspireExtension.clientDetails.payments.creditCards.setDefault',
                            )}
                        </MenuItem>
                    )}
                    {onDelete && (
                        <MenuItem
                            dense
                            classes={{
                                root: classnames(styles.menuItem, {
                                    [styles.disabled]:
                                        isDefault &&
                                        clientHasPatientsWithActivePlans !==
                                            false,
                                }),
                            }}
                            onClick={handleDelete}
                        >
                            {isDefault &&
                            clientHasPatientsWithActivePlans !== false ? (
                                <Tooltip
                                    placement="top"
                                    classes={{
                                        tooltip: styles.tooltip,
                                    }}
                                    title={translate(
                                        'vetspireExtension.clientDetails.payments.creditCards.delete.clientHasPatientsWithActivePlans',
                                    )}
                                >
                                    <span>
                                        {translate(
                                            'vetspireExtension.clientDetails.payments.creditCards.delete.label',
                                        )}
                                    </span>
                                </Tooltip>
                            ) : (
                                <span>
                                    {translate(
                                        'vetspireExtension.clientDetails.payments.creditCards.delete.label',
                                    )}
                                </span>
                            )}
                        </MenuItem>
                    )}
                    {addChargeNoShowFeesOptions && (
                        <MenuItem
                            dense
                            classes={{ root: styles.menuItem }}
                            onClick={(event) =>
                                handleFeeProduct(
                                    event,
                                    FEE_PRODUCT_TYPE_NO_SHOW,
                                )
                            }
                        >
                            {translate(
                                'vetspireExtension.clientDetails.payments.creditCards.chargeNoShowFee.label',
                            )}
                        </MenuItem>
                    )}
                    {addChargeNoShowFeesOptions && (
                        <MenuItem
                            dense
                            classes={{ root: styles.menuItem }}
                            onClick={(event) =>
                                handleFeeProduct(event, 'cancel')
                            }
                        >
                            {translate(
                                'vetspireExtension.clientDetails.payments.creditCards.chargeCancelFee.label',
                            )}
                        </MenuItem>
                    )}
                    {addChargeNoShowFeesOptions && (
                        <MenuItem
                            dense
                            classes={{ root: styles.menuItem }}
                            onClick={(event) =>
                                handleFeeProduct(event, 'veryLateCancel')
                            }
                        >
                            {translate(
                                'vetspireExtension.clientDetails.payments.creditCards.chargeVeryLateCancelFee.label',
                            )}
                        </MenuItem>
                    )}
                    {addChargeNoShowFeesOptions && (
                        <MenuItem
                            dense
                            classes={{ root: styles.menuItem }}
                            onClick={(event) =>
                                handleFeeProduct(event, 'reschedule')
                            }
                        >
                            {translate(
                                'vetspireExtension.clientDetails.payments.creditCards.chargeRescheduleFee.label',
                            )}
                        </MenuItem>
                    )}
                    {addChargeNoShowFeesOptions && (
                        <MenuItem
                            dense
                            classes={{ root: styles.menuItem }}
                            onClick={(event) =>
                                handleFeeProduct(event, 'veryLateReschedule')
                            }
                        >
                            {translate(
                                'vetspireExtension.clientDetails.payments.creditCards.chargeVeryLateRescheduleFee.label',
                            )}
                        </MenuItem>
                    )}
                </Menu>
            )}

            <Dialog open={showDeleteModal}>
                <DialogTitle>
                    {translate(
                        'vetspireExtension.clientDetails.payments.creditCards.delete.title',
                    )}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {translate(
                            'vetspireExtension.clientDetails.payments.creditCards.delete.confirm',
                            { card: label },
                        )}
                    </DialogContentText>
                    <DialogActions>
                        <Button type="button" onClick={handleCancelDelete}>
                            {translate(
                                'vetspireExtension.clientDetails.payments.creditCards.delete.cancel',
                            )}
                        </Button>
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={handleSubmitDelete}
                        >
                            {translate(
                                'vetspireExtension.clientDetails.payments.creditCards.delete.submit',
                            )}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>

            {showFeeModal && (
                <ChargeFeeProduct
                    last4={last4}
                    feeProductType={showFeeModal}
                    onClose={closeFeeProductModal}
                />
            )}
        </>
    );
}

export default Card;
