// eslint-disable-next-line import/no-cycle
import { VetspireAppointmentStatus } from '../vetspire';

export const FEE_PRODUCT_TYPE_NO_SHOW = 'noShow';
export const FEE_PRODUCT_TYPE_CANCEL = 'cancel';
export const FEE_PRODUCT_TYPE_RESCHEDULE = 'reschedule';
export const FEE_PRODUCT_TYPE_VERY_LATE_RESCHEDULE = 'veryLateReschedule';
export const FEE_PRODUCT_TYPE_VERY_LATE_CANCEL = 'veryLateCancel';
export const FEE_PRODUCT_TYPE_SPECIALTY_SURGERY_CONSULTATION =
    'specialtySurgeryConsultation';

export type CancelFeeProductType =
    | typeof FEE_PRODUCT_TYPE_CANCEL
    | typeof FEE_PRODUCT_TYPE_VERY_LATE_CANCEL;

export type RescheduleFeeProductType =
    | typeof FEE_PRODUCT_TYPE_RESCHEDULE
    | typeof FEE_PRODUCT_TYPE_VERY_LATE_RESCHEDULE;

export type AppointmentFeeProductType =
    | CancelFeeProductType
    | RescheduleFeeProductType
    | typeof FEE_PRODUCT_TYPE_NO_SHOW;

export type FeeProductType =
    | AppointmentFeeProductType
    | typeof FEE_PRODUCT_TYPE_SPECIALTY_SURGERY_CONSULTATION;

export type FeeProduct = {
    _id: FeeProductType;
    productId: string;
    name: string;
    price?: number;
};

export interface FeeProductsQueryArguments {
    appointmentId?: string;
    locationId?: string;
    vetspireLocationId?: string;
}

export interface FeeProductsQueryResult {
    feeProducts: readonly FeeProduct[];
}

export const FEE_PRODUCTS_COLLECTION_NAME = 'settings.feeProducts';

export const NO_SHOW_CHARGING_COLLECTION_NAME = 'settings.noShowCharging';
export const NO_SHOW_CHARGES_COLLECTION_NAME = 'noShows.charges';

export const NO_SHOW_AUTOMATED_CHARGES_PATH =
    'noShows.automatedCharging.charges';

export const NO_SHOW_CHARGING_ENABLED = 'enabled';
export const NO_SHOW_CHARGING_HOUR = 'hourOfDay';

export type NoShowChargingEnabledSetting = {
    _id: typeof NO_SHOW_CHARGING_ENABLED;
    enabled: boolean;
};

export type NoShowChargingHourSetting = {
    _id: typeof NO_SHOW_CHARGING_HOUR;
    hourOfDay: number;
};

export type NoShowChargingSetting =
    | NoShowChargingEnabledSetting
    | NoShowChargingHourSetting;

export type NoShowChargingSettingType = NoShowChargingSetting['_id'];

export interface NoShowChargeData {
    appointmentId: string;
    clientId: string;
    patientId: string;
    date: string;
    status: VetspireAppointmentStatus;
    locationId: string;
}

export interface AutomatedNoShowCharge extends NoShowChargeData {
    _id: string;
    createdAt: Date;
    processed: boolean;
    processing: boolean;
    processedAt?: Date;
    lastFailedAt?: Date;
    lastError?: string;
    attempts: number;
}
