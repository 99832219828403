import * as React from 'react';
import IntradayCapacity from 'pages/IntradayCapacity';
import IntradayCapacityModal from 'pages/IntradayCapacity/IntradayCapacityModal';
import SmsConversationalOptIn from 'pages/SmsConversationalOptIn';
import PatientPlanSignUp from 'pages/PatientPlanSignUp';
import TravelCertificates from 'pages/TravelCertificates';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { LocalizeProvider } from 'react-localize-redux';
import FirebaseContext from 'hooks/firebase/context';
import useFirebaseContextHandler from 'hooks/firebase';
import initialize from 'lib/i18n';
import NavigationBar from 'pages/NavigationBar';
import Tasks from 'pages/Tasks';
import SurgeryForms from 'pages/SurgeryForms';
import Encounters from 'pages/Encounters/Encounters';
import VetspireContextProvider from 'context/VetspireContext';
import { ModalPages, Page } from 'lib/vetspireActions';
import ClientDetails from 'pages/ClientDetails';
import Telehealth from 'pages/Telehealth';
import ProviderPayment from 'components/ProviderPayment/ProviderPayment';
import SurgeryReferrals from 'pages/SurgeryReferrals';
import Bonusly from 'pages/Bonusly/Bonusly';
import ApptLog from 'pages/AppointmentLog/AppointmentLog';
import InfoPDF from 'pages/InfoPdf';
import ExternalReferrals from 'pages/ExternalReferrals';
import Suki from 'pages/Suki';
import useGlobalFlyoutEffects from 'hooks/useGlobalFlyoutEffects';
import MedicalMishaps from 'pages/MedicalMishaps';
import BioQuestionnaire from 'pages/BioQuestionnaire';
import { ThemeProvider } from '@mui/material/styles';
import MonthlyPulseReport from 'components/MonthlyPulseReport/components/MonthlyPulseReport';
import MonthlyPulseReportFrame from 'components/MonthlyPulseReport/components/MonthlyPulseReportFrame';
import Texting from 'pages/Texting';
import PatientPlans from 'pages/PatientPlans';
import DigitalWalkIns from 'pages/DigitalWalkIns';
import theme from 'styles/muiTheme';

export default function App(): React.ReactElement {
    const firebaseContext = useFirebaseContextHandler();
    useGlobalFlyoutEffects();

    return (
        <ThemeProvider theme={theme}>
            <LocalizeProvider initialize={initialize}>
                <VetspireContextProvider>
                    <FirebaseContext.Provider value={firebaseContext}>
                        <BrowserRouter>
                            <Switch>
                                <Route exact path="/">
                                    <NavigationBar />
                                </Route>
                                <Route exact path={`/${Page.digitalWalkIns}`}>
                                    <DigitalWalkIns />
                                </Route>
                                <Route exact path={`/${Page.tasks}`}>
                                    <Tasks />
                                </Route>
                                <Route exact path={`/${Page.surgeryForms}`}>
                                    <SurgeryForms />
                                </Route>
                                <Route exact path={`/${Page.surgeryReferrals}`}>
                                    <SurgeryReferrals />
                                </Route>
                                <Route exact path={`/${Page.encounters}`}>
                                    <Encounters />
                                </Route>
                                <Route exact path={`/${Page.clientDetails}`}>
                                    <ClientDetails />
                                </Route>
                                <Route exact path={`/${Page.providerPayment}`}>
                                    <ProviderPayment />
                                </Route>
                                <Route exact path={`/${Page.bonusly}`}>
                                    <Bonusly />
                                </Route>
                                <Route
                                    exact
                                    path={`/${Page.pulseMonthlyReport}`}
                                >
                                    <MonthlyPulseReport />
                                </Route>
                                <Route
                                    exact
                                    path={`/${Page.pulseMonthlyReportFrame}`}
                                >
                                    <MonthlyPulseReportFrame />
                                </Route>
                                <Route exact path={`/${Page.apptLog}`}>
                                    <ApptLog />
                                </Route>
                                <Route exact path={`/${Page.infoPDF}`}>
                                    <InfoPDF />
                                </Route>
                                <Route
                                    exact
                                    path={`/${Page.externalReferrals}`}
                                >
                                    <ExternalReferrals />
                                </Route>
                                <Route exact path={`/${Page.medicalMishaps}`}>
                                    <MedicalMishaps />
                                </Route>
                                <Route exact path={`/${Page.suki}`}>
                                    <Suki />
                                </Route>
                                <Route exact path={`/${Page.telehealth}`}>
                                    <Telehealth />
                                </Route>
                                <Route exact path={`/${Page.texting}`}>
                                    <Texting />
                                </Route>
                                <Route exact path={`/${Page.patientPlans}`}>
                                    <PatientPlans />
                                </Route>
                                <Route
                                    exact
                                    path={`/${Page.patientPlanSignUp}`}
                                >
                                    <PatientPlanSignUp />
                                </Route>
                                <Route
                                    exact
                                    path={`/${Page.travelCertificates}`}
                                >
                                    <TravelCertificates />
                                </Route>

                                <Route
                                    exact
                                    path={`/${ModalPages.smsConversationalOptIn}`}
                                >
                                    <SmsConversationalOptIn />
                                </Route>

                                <Route exact path={`/${Page.intradayCapacity}`}>
                                    <IntradayCapacity />
                                </Route>

                                <Route
                                    exact
                                    path={`/${ModalPages.intradayCapacityModal}`}
                                >
                                    <IntradayCapacityModal />
                                </Route>
                                <Route exact path={`/${Page.questionnaire}`}>
                                    <BioQuestionnaire />
                                </Route>
                            </Switch>
                        </BrowserRouter>
                    </FirebaseContext.Provider>
                </VetspireContextProvider>
            </LocalizeProvider>
        </ThemeProvider>
    );
}
