import * as React from 'react';
import { CollectionReference } from '@firebase/firestore';
import { onSnapshot, query, QueryConstraint, where } from 'firebase/firestore';
import { format, startOfDay } from 'date-fns';
import { CircularProgress } from '@mui/material';
import { useQuery } from '@apollo/client';

import { DIGITAL_WALK_INS_COLLECTION_NAME } from '@bondvet/types/digitalWalkIns/constants';
import {
    ClientDigitalWalkIn,
    UpdateDigitalWalkInStatusArguments,
    DigitalWalkInsEnabledArguments,
    CreateAppointmentFromDigitalWalkInArguments,
} from '@bondvet/types/digitalWalkIns';
import useTranslate from 'hooks/useTranslate';
import useLocationId from 'hooks/useLocationId';
import useFirebaseContext from 'hooks/firebase/useFirebaseContext';
import useBondQuery from 'hooks/useBondQuery';
import useBondMutation from 'hooks/useBondMutation';
import { updateCalendar } from 'lib/vetspireActions';

import {
    DigitalWalkInsEnabledQueryResult,
    digitalWalkInsEnabledQuery,
    updateDigitalWalkInStatusMutation,
    createAppointmentFromDigitalWalkInMutation,
    CreateAppointmentFromDigitalWalkInResult,
    UpdateDigitalWalKInStatusResult,
    vetspireAppointmentTypesQuery,
    VetspireAppointmentTypesQueryResult,
} from './api';

import WalkIn from './components/WalkIn';
import ArrivedDialog from './components/ArrivedDialog';
import ConfirmationDialog from './components/ConfirmationDialog';

import styles from './DigitalWalkIns.module.scss';
// Helper to sort the digital walkins...
// [00][timestamp] is not arrived yet
// [01][timestamp] is arrived
// [10][timestamp] is noShow
const getSortString = (walkIn: ClientDigitalWalkIn) =>
    `${walkIn.isNoShow ? 1 : 0}${walkIn.hasArrived ? 1 : 0}${walkIn.date.toDate().getTime()}`;

const DigitalWalkIns: React.FunctionComponent = () => {
    const translate = useTranslate();
    const { getCollection } = useFirebaseContext();
    const locationId = useLocationId();

    const [arrivedModalData, setArrivedModalData] =
        React.useState<ClientDigitalWalkIn | null>(null);
    const [deleteId, setDeleteId] = React.useState<string | null>(null);
    const [markNoShowId, setMarkNoShowId] = React.useState<string | null>(null);
    const [markNotArrivedYetId, setMarkNotArrivedYetId] = React.useState<
        string | null
    >(null);

    const [updateAppointmentLoading, setUpdateAppointmentLoading] =
        React.useState(false);

    const [createAppointmentStatus, setCreateAppointmentStatus] =
        React.useState<{ loading: boolean; error: null | string }>({
            loading: false,
            error: null,
        });

    const [digitalWalkInsCollection, setDigitalWalkInsCollection] =
        React.useState<CollectionReference | null>(null);

    const [todaysWalkIns, setTodaysWalkIns] = React.useState<
        ClientDigitalWalkIn[] | null
    >(null);

    // Gets the digital walkins collection from Firestore
    React.useEffect(() => {
        getCollection(DIGITAL_WALK_INS_COLLECTION_NAME).then((c) => {
            setDigitalWalkInsCollection(c);
        });
    }, [getCollection]);

    const { data: appointmentTypesData } =
        useQuery<VetspireAppointmentTypesQueryResult>(
            vetspireAppointmentTypesQuery,
        );

    const { data: enabledData, loading: enabledStatusLoading } = useBondQuery<
        DigitalWalkInsEnabledQueryResult,
        DigitalWalkInsEnabledArguments
    >(digitalWalkInsEnabledQuery, {
        variables: { vetspireLocationId: locationId },
        fetchPolicy: 'cache-and-network',
    });

    const [updateDigitalWalkInStatus] = useBondMutation<
        UpdateDigitalWalKInStatusResult,
        UpdateDigitalWalkInStatusArguments
    >(updateDigitalWalkInStatusMutation, { fetchPolicy: 'no-cache' });

    const [createAppointment] = useBondMutation<
        CreateAppointmentFromDigitalWalkInResult,
        CreateAppointmentFromDigitalWalkInArguments
    >(createAppointmentFromDigitalWalkInMutation);

    const handleCreateAppointment = React.useCallback(
        (appointmentVariables: CreateAppointmentFromDigitalWalkInArguments) => {
            setCreateAppointmentStatus({ loading: true, error: null });
            createAppointment({ variables: appointmentVariables }).then(
                (result) => {
                    if (
                        result?.data?.createAppointmentFromDigitalWalkIn
                            ?.success
                    ) {
                        updateCalendar();

                        updateDigitalWalkInStatus({
                            variables: {
                                hasArrived: true,
                                isNoShow: false,
                                digitalWalkInId:
                                    appointmentVariables.digitalWalkInId,
                                isDeleted: false,
                            },
                        }).then(() => {
                            setArrivedModalData(null);
                            setCreateAppointmentStatus({
                                loading: false,
                                error: null,
                            });
                        });
                    } else {
                        setCreateAppointmentStatus({
                            loading: false,
                            error:
                                result?.errors?.join(', ') ||
                                'Error creating appointment',
                        });
                    }
                },
            );
        },
        [createAppointment, updateDigitalWalkInStatus, setArrivedModalData],
    );

    const handleMarkNoShow = React.useCallback(() => {
        if (markNoShowId) {
            setUpdateAppointmentLoading(true);
            updateDigitalWalkInStatus({
                variables: {
                    hasArrived: false,
                    isNoShow: true,
                    digitalWalkInId: markNoShowId,
                    isDeleted: false,
                },
            }).then(() => {
                updateCalendar();
                setMarkNoShowId(null);
                setUpdateAppointmentLoading(false);
            });
        }
    }, [markNoShowId, updateDigitalWalkInStatus, setMarkNoShowId]);

    const handleMarkNotArrivedYet = React.useCallback(() => {
        if (markNotArrivedYetId) {
            setUpdateAppointmentLoading(true);
            updateDigitalWalkInStatus({
                variables: {
                    hasArrived: false,
                    isNoShow: false,
                    digitalWalkInId: markNotArrivedYetId,
                    isDeleted: false,
                },
            }).then(() => {
                updateCalendar();
                setMarkNotArrivedYetId(null);
                setUpdateAppointmentLoading(false);
            });
        }
    }, [
        markNotArrivedYetId,
        updateDigitalWalkInStatus,
        setMarkNotArrivedYetId,
    ]);

    const handleDelete = React.useCallback(() => {
        if (deleteId) {
            setUpdateAppointmentLoading(true);

            const variables = {
                isDeleted: true,
                hasArrived: false,
                isNoShow: false,
                digitalWalkInId: deleteId,
            };

            updateDigitalWalkInStatus({
                variables,
            }).then(() => {
                updateCalendar();
                setDeleteId(null);
                setUpdateAppointmentLoading(false);
            });
        }
    }, [deleteId, updateDigitalWalkInStatus, setDeleteId]);

    // Snapshot listener for updates to the digital walk-ins collection
    React.useEffect(() => {
        let unsubscribe = () => {};

        if (digitalWalkInsCollection) {
            const queryConstraints: QueryConstraint[] = [
                where('vetspireLocationId', '==', locationId),
                where('isDeleted', '==', false),
                where('date', '>=', startOfDay(new Date())),
            ];

            const sessionQuery = query(
                digitalWalkInsCollection,
                ...queryConstraints,
            );

            unsubscribe = onSnapshot(sessionQuery, (snapshot) => {
                const updatedWalkIns: ClientDigitalWalkIn[] = [];
                snapshot.forEach((doc) => {
                    const data = doc.data() as ClientDigitalWalkIn;
                    updatedWalkIns.push({
                        ...data,
                        id: doc.id,
                    });
                });

                // Walk-Ins should be sorted by Not Arrived Yet, then Arrived, then No Shows
                // Within those statuses, sort by soonest first
                setTodaysWalkIns(
                    updatedWalkIns.sort((walkInA, walkInB) => {
                        return getSortString(walkInA).localeCompare(
                            getSortString(walkInB),
                        );
                    }),
                );
            });
        }

        return unsubscribe;
    }, [digitalWalkInsCollection, getCollection, locationId]);

    const content = React.useMemo(() => {
        if (enabledStatusLoading) {
            return <CircularProgress size={24} />;
        }

        if (enabledData && !enabledData?.digitalWalkInsEnabled) {
            return (
                <div className={styles.notEnabled}>
                    {translate('vetspireExtension.digitalWalkIns.disabled')}
                </div>
            );
        }

        return (
            <>
                <div className={styles.description}>
                    <p>
                        {translate(
                            'vetspireExtension.digitalWalkIns.description',
                            undefined,
                            {
                                renderInnerHtml: true,
                            },
                        )}
                    </p>
                    <p className={styles.bold}>
                        {translate(
                            'vetspireExtension.digitalWalkIns.note',
                            undefined,
                            {
                                renderInnerHtml: true,
                            },
                        )}
                    </p>
                    <div className={styles.date}>
                        {translate('vetspireExtension.digitalWalkIns.date', {
                            date: format(new Date(), 'MMMM dd'),
                        })}
                    </div>
                </div>
                {todaysWalkIns === null && <CircularProgress size={24} />}
                {todaysWalkIns !== null &&
                    (todaysWalkIns.length > 0 ? (
                        <div className={styles.walkInsList}>
                            {todaysWalkIns.map((walkIn) => (
                                <WalkIn
                                    key={walkIn.id}
                                    walkInData={walkIn}
                                    setArrivedModalData={setArrivedModalData}
                                    handleMarkNoShow={() =>
                                        setMarkNoShowId(walkIn.id)
                                    }
                                    handleMarkNotArrivedYet={() =>
                                        setMarkNotArrivedYetId(walkIn.id)
                                    }
                                    handleDelete={() => setDeleteId(walkIn.id)}
                                ></WalkIn>
                            ))}
                        </div>
                    ) : (
                        <div className={styles.noWalkIns}>
                            {translate(
                                'vetspireExtension.digitalWalkIns.walkIns.empty',
                            )}
                        </div>
                    ))}
            </>
        );
    }, [
        todaysWalkIns,
        enabledData,
        enabledStatusLoading,
        translate,
        setMarkNoShowId,
        setMarkNotArrivedYetId,
    ]);

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                {translate('vetspireExtension.digitalWalkIns.title')}
            </div>
            {content}
            {arrivedModalData && (
                <div className={styles.arrivedModal}>
                    <ArrivedDialog
                        walkIn={arrivedModalData}
                        onClose={() => setArrivedModalData(null)}
                        handleSubmit={handleCreateAppointment}
                        appointmentTypes={
                            appointmentTypesData?.appointmentTypes || []
                        }
                        status={createAppointmentStatus}
                    />
                </div>
            )}
            {markNoShowId && (
                <ConfirmationDialog
                    action={'noShow'}
                    onClose={() => setMarkNoShowId(null)}
                    handleSubmit={handleMarkNoShow}
                    loading={updateAppointmentLoading}
                />
            )}
            {markNotArrivedYetId && (
                <ConfirmationDialog
                    action={'markNotArrived'}
                    onClose={() => setMarkNotArrivedYetId(null)}
                    handleSubmit={handleMarkNotArrivedYet}
                    loading={updateAppointmentLoading}
                />
            )}
            {deleteId && (
                <ConfirmationDialog
                    action={'delete'}
                    onClose={() => setDeleteId(null)}
                    handleSubmit={handleDelete}
                    loading={updateAppointmentLoading}
                />
            )}
        </div>
    );
};

export default DigitalWalkIns;
