import * as React from 'react';
import Alert from 'components/Alert';
import CheckIcon from '@mui/icons-material/Check';
import useTranslate from 'hooks/useTranslate';
import usePatientDetails from 'hooks/usePatientDetails';
import ActionButton from 'components/ActionButton';
import classnames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import useBondQuery from 'hooks/useBondQuery';
import PreferredClinic from '../PreferredClinic';
import styles from './SendRecommendation.module.scss';
import SelectSurgeryType from '../SelectSurgeryType';
import { sendSurgeryReferralContext } from '../../context';
import QuestionForm from '../QuestionForm';
import useForm from './useForm';
import Notes from '../Notes';
import RecommendForEducationalFund from '../RecommendForEducationalFund';
import RecommendedDate from '../RecommendedDate';
import RecommendedSurgeons from '../RecommendedSurgeons';
import ScheduleConsultation from '../ScheduleConsultation';
import ConsultationSentDialog from '../ConsultationSentDialog';
import {
    surgeryLocationsQuery,
    SurgeryLocationsQuery,
} from '../../api/queries';

type SendRecommendationProps = {
    clientId: string | null;
    patientId: string | null;
};

export default function SendRecommendation({
    clientId,
    patientId,
}: SendRecommendationProps): React.ReactElement {
    const translate = useTranslate();
    const { details } = usePatientDetails(patientId);
    const {
        changeSurgeryType,
        answers,
        valid,
        submit,
        submitting,
        error,
        loading,
        succeeded,
        surgeonOptions,
        recommendedSpecialtySurgeon,
        checkboxValues,
        changeCheckboxValues,
        selectedConsultationTime,
        setSelectedConsultationTime,
        specialtySurgeryConsultationFee,
        specialtySurgeriesEnabled,
        resetForm,
        ...contextData
    } = useForm(clientId, patientId);

    const [showSuccessModal, setShowSuccessModal] = React.useState(false);

    React.useEffect(() => {
        if (succeeded && contextData.surgeryType?.isSpecialtySurgery) {
            setShowSuccessModal(true);
        }
    }, [contextData.surgeryType?.isSpecialtySurgery, succeeded]);

    const questions = React.useMemo(
        () =>
            answers
                .slice(0)
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map((answer) => (
                    <QuestionForm answer={answer} key={answer.id} />
                )),
        [answers],
    );

    const { data: surgeryLocationsData } = useBondQuery<SurgeryLocationsQuery>(
        surgeryLocationsQuery,
        {
            fetchPolicy: 'cache-and-network',
        },
    );

    const { surgeryType, preferredClinic: preferredClinicId } = contextData;

    const preferredClinic = React.useMemo(() => {
        if (preferredClinicId) {
            return (
                surgeryLocationsData?.locations.find(
                    ({ _vetspire }) => _vetspire?.id === preferredClinicId,
                ) ?? null
            );
        }
        return null;
    }, [preferredClinicId, surgeryLocationsData?.locations]);

    if (!patientId) {
        return (
            <Alert>
                {translate(
                    'vetspireExtension.surgeryReferrals.send.noPatientRoute',
                )}
            </Alert>
        );
    }

    let action = 'submit';
    if (succeeded) {
        action = 'sent';
    } else if (surgeryType?.isSpecialtySurgery) {
        action = 'submitSpecialtySurgery';
    }

    return (
        <sendSurgeryReferralContext.Provider value={contextData}>
            <form onSubmit={submit} className={styles.container}>
                {loading && (
                    <div className={styles.loading}>
                        <CircularProgress />
                    </div>
                )}

                {!loading && (
                    <SelectSurgeryType
                        value={surgeryType}
                        onChange={changeSurgeryType}
                        allowSpecialtySurgeries={specialtySurgeriesEnabled}
                        label={
                            <>
                                {translate(
                                    'vetspireExtension.surgeryReferrals.send.intro1',
                                )}{' '}
                                <strong>{details?.name}</strong>{' '}
                                {translate(
                                    'vetspireExtension.surgeryReferrals.send.intro2',
                                )}
                            </>
                        }
                    />
                )}

                {surgeryType && !loading && (
                    <>
                        <RecommendedDate />
                        <PreferredClinic
                            surgeryType={surgeryType}
                            surgeryLocations={
                                surgeryLocationsData?.locations ?? []
                            }
                        />
                        <RecommendedSurgeons
                            surgeryType={surgeryType}
                            surgeonOptions={surgeonOptions}
                        />
                        {questions}
                        <Notes />
                        <RecommendForEducationalFund />
                    </>
                )}

                {surgeryType?.isSpecialtySurgery &&
                    recommendedSpecialtySurgeon && (
                        <ScheduleConsultation
                            consultationFee={specialtySurgeryConsultationFee}
                            surgeon={recommendedSpecialtySurgeon}
                            checkboxValues={checkboxValues}
                            changeCheckboxValues={changeCheckboxValues}
                            selectedTime={selectedConsultationTime}
                            changeSelectedTime={setSelectedConsultationTime}
                        />
                    )}

                {error && <Alert>{error}</Alert>}

                {!loading && (
                    <div className={styles.actions}>
                        <ActionButton
                            disabled={
                                !valid ||
                                !surgeryType ||
                                submitting ||
                                succeeded
                            }
                            type="submit"
                            className={classnames(styles.button, {
                                [styles.submit]: !succeeded,
                                [styles.succeeded]: succeeded,
                            })}
                        >
                            {succeeded && <CheckIcon className={styles.icon} />}
                            {translate(
                                `vetspireExtension.surgeryReferrals.send.${action}`,
                            )}
                        </ActionButton>
                    </div>
                )}
            </form>
            {showSuccessModal && (
                <ConsultationSentDialog
                    pet={details?.name ?? ''}
                    appointment={surgeryType?.name ?? ''}
                    date={selectedConsultationTime ?? ''}
                    provider={recommendedSpecialtySurgeon?.name ?? ''}
                    location={preferredClinic?.name ?? ''}
                    onClose={() => {
                        resetForm();
                        setShowSuccessModal(false);
                    }}
                />
            )}
        </sendSurgeryReferralContext.Provider>
    );
}
