import * as React from 'react';
import { useQuery } from '@apollo/client';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import {
    GraphQLSurgeryReferral,
    surgeryReferrals,
    SurgeryReferralsQuery,
} from '../api/queries';

type SurgeryReferrals = {
    referrals: ReadonlyArray<GraphQLSurgeryReferral>;
    loading: boolean;
};

function compareReferrals(
    a: GraphQLSurgeryReferral,
    b: GraphQLSurgeryReferral,
): number {
    const aIsLinked = !!a.linkedInvoices?.find(({ invoiceId }) => !!invoiceId);
    const bIsLinked = !!b.linkedInvoices?.find(({ invoiceId }) => !!invoiceId);
    if (aIsLinked === bIsLinked) {
        // either both are linked or both are unlinked
        return a.surgeryType.name.localeCompare(b.surgeryType.name);
    }

    if (aIsLinked) {
        // a is linked, but b isn't
        return 1;
    }

    // b is linked, but a isn't
    return -1;
}

export default function useSurgeryReferrals(
    patientId: string | null,
): SurgeryReferrals {
    const { loading, data } = useQuery<SurgeryReferralsQuery>(
        surgeryReferrals,
        {
            context: { clientName: GRAPHQL_CLIENT_NAMES.default },
            variables: { patientId },
            fetchPolicy: 'cache-and-network',
            skip: !patientId,
        },
    );

    const referrals = React.useMemo<ReadonlyArray<GraphQLSurgeryReferral>>(
        () => (data?.surgeryReferrals || []).slice(0).sort(compareReferrals),
        [data?.surgeryReferrals],
    );

    return {
        referrals,
        loading,
    };
}
