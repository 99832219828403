export const GraphqlClientNames = [
    'default', // default must be the first here to be the last in the link-chain in client.ts
    'analytics',
    'auth',
    'booking',
    'clientApp',
    'creditCards',
    'gainSharing',
    'providerPayment',
    'scheduling',
    'suki',
    'telehealth',
    'textMessaging',
    'vetspire',
    'patientPlans',
    'giftCards',
    'website',
] as const;

export type GraphqlClientName = (typeof GraphqlClientNames)[number];

type GraphqlClientNamesType = Readonly<{
    [key in GraphqlClientName]: GraphqlClientName;
}>;

export const GRAPHQL_CLIENT_NAMES = GraphqlClientNames.reduce(
    (acc, clientName) => {
        return {
            ...acc,
            [clientName]: clientName,
        };
    },
    {},
) as GraphqlClientNamesType;
