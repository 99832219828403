import useLazyAppointmentTypes from 'hooks/useLazyAppointmentTypes';
import * as React from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import useGlobalBookingSettings from 'hooks/useGlobalBookingSettings';
import useVetspireSettings from 'hooks/useVetspireSettings';
import useRequestInterceptor, {
    InterceptorPayload,
    InterceptorResponse,
} from 'hooks/useRequestInterceptor';
import {
    closeModal,
    ignoreAlerts,
    openFlyout,
    Page,
} from 'lib/vetspireActions';
import {
    RootMutationTypeDeleteAppointmentArgs,
    RootMutationTypeUpdateAppointmentArgs,
} from '@bondvet/types/generated/vetspire';
import { subHours } from 'date-fns';
import { AppointmentFeeProductType } from '@bondvet/types/noShows';

const DELETE_APPOINTMENT_OPERATION_NAME = 'deleteAppointment';
const DELETE_APPT_OPERATION_NAME = 'deleteAppt';
const UPDATE_APPOINTMENT_OPERATION_NAME = 'updateAppointment';
const UPDATE_EVENT_LOCATION_OPERATION_NAME = 'updateEventLocation';

export const appointmentQuery = gql`
    query appointment($id: ID!) {
        appointment(id: $id) {
            id
            start
            insertedAt
            location {
                id
                timezone
            }
            patient {
                id
                client {
                    id
                }
            }
            schedule {
                id
                name
            }
            department {
                id
                name
            }
            type {
                id
                name
            }
        }
    }
`;

interface AppointmentQueryResult {
    appointment: {
        id: string;
        start: string;
        insertedAt: string;
        location: {
            id: string;
            timezone: string;
        };
        patient: {
            id: string;
            client: {
                id: string;
            };
        };
        schedule: {
            id: string;
            name: string;
        };
        department: {
            id: string;
            name: string;
        };
        type: {
            id: string;
            name: string;
        };
    };
}

interface AppointmentQueryVariables {
    id: string;
}

export default function useLateCancelReschedule() {
    const { settingsById } = useGlobalBookingSettings();
    const vetspireSettings = useVetspireSettings();

    const [getAppointment] = useLazyQuery<
        AppointmentQueryResult,
        AppointmentQueryVariables
    >(appointmentQuery, { fetchPolicy: 'no-cache' });

    const [getAppointmentTypes] = useLazyAppointmentTypes();

    const triggerChargeFeeProductType = React.useCallback(
        async (
            feeProductType: AppointmentFeeProductType,
            appointment: AppointmentQueryResult['appointment'],
            originalQuery: string,
            originalVariables: Record<string, unknown>,
        ) => {
            const params = new URLSearchParams();
            params.set('feeProductType', feeProductType);
            params.set('appointmentId', appointment.id);
            params.set('locationId', appointment?.location?.id || '');
            params.set('clientId', appointment?.patient?.client?.id || '');
            params.set('patientId', appointment?.patient?.id || '');
            params.set('originalQuery', originalQuery);
            params.set('originalVariables', JSON.stringify(originalVariables));

            if (appointment?.patient?.client?.id) {
                openFlyout(Page.clientDetails, `?${params.toString()}`);
            }
        },
        [],
    );

    const isProcedure = React.useCallback(
        (appointment: AppointmentQueryResult['appointment']) => {
            if (
                (vetspireSettings.noChargeFeeSchedules || []).find(
                    (schedule) =>
                        appointment?.schedule?.name?.toLowerCase().trim() ===
                        schedule.toLowerCase().trim(),
                )
            ) {
                return true;
            }

            if (
                (vetspireSettings.noChargeFeeDepartments || []).find(
                    (department) =>
                        appointment?.department?.name?.toLowerCase().trim() ===
                        department.toLowerCase().trim(),
                )
            ) {
                return true;
            }

            return false;
        },
        [
            vetspireSettings.noChargeFeeDepartments,
            vetspireSettings.noChargeFeeSchedules,
        ],
    );

    const getAppointmentAndCheckBasics = React.useCallback(
        async (
            appointmentId: string,
        ): Promise<
            | {
                  appointment: AppointmentQueryResult['appointment'] | null;
                  start: Date;
                  runOriginalRequest: false;
              }
            | {
                  appointment: null;
                  start: null;
                  runOriginalRequest: true;
              }
        > => {
            const { data } = await getAppointment({
                variables: { id: appointmentId },
            });

            const { data: appointmentTypesData } = await getAppointmentTypes();

            if (!data?.appointment) {
                // should never happen, but without appointment we'll just execute the original request
                return {
                    appointment: null,
                    runOriginalRequest: true,
                    start: null,
                };
            }

            const {
                start: startS,
                insertedAt: insertedAtS,
                type,
                department,
            } = data.appointment;

            if (/Digital Walk-in/i.test(department?.name ?? '')) {
                console.info(
                    'do not intercept - this appointment is on the "%s" department',
                    department?.name,
                );
                return {
                    appointment: null,
                    runOriginalRequest: true,
                    start: null,
                };
            }

            if (type?.id) {
                const appointmentType =
                    appointmentTypesData?.appointmentTypes.find(
                        (searchAppointmentType) =>
                            searchAppointmentType._id === type.id,
                    );

                if (appointmentType?.chargeLateCancelRescheduleFee === false) {
                    console.info(
                        'do not intercept - appointmentType.chargeLateCancelRescheduleFee is false',
                    );
                    return {
                        appointment: null,
                        runOriginalRequest: true,
                        start: null,
                    };
                }
            }

            const start = new Date(startS);
            const insertedAt = new Date(`${insertedAtS}Z`);

            if (
                settingsById.applyBookingFeeGracePeriod.enabled &&
                subHours(
                    new Date(),
                    settingsById.applyBookingFeeGracePeriod.hours,
                ) < insertedAt
            ) {
                console.info(
                    'do not intercept - we are within the grace period',
                );

                return {
                    appointment: null,
                    runOriginalRequest: true,
                    start: null,
                };
            }

            return {
                appointment: data.appointment,
                runOriginalRequest: false,
                start,
            };
        },
        [
            getAppointment,
            getAppointmentTypes,
            settingsById.applyBookingFeeGracePeriod.enabled,
            settingsById.applyBookingFeeGracePeriod.hours,
        ],
    );

    const handleDeleteRequest = React.useCallback(
        async <
            Variables extends Record<string, unknown> = Record<string, unknown>,
        >(
            appointmentId: string,
            originalQuery: string,
            originalVariables: Variables,
        ): Promise<InterceptorResponse<Variables>> => {
            try {
                const { appointment, runOriginalRequest, start } =
                    await getAppointmentAndCheckBasics(appointmentId);

                const procedure = appointment && isProcedure(appointment);

                if (
                    runOriginalRequest ||
                    !appointment ||
                    !appointment.patient ||
                    procedure
                ) {
                    console.info(
                        `do not intercept: runOriginalRequest=${runOriginalRequest}, !appointment=${!appointment}, !appointment.patient=${!appointment?.patient}, procedure=${procedure}`,
                    );
                    return {
                        runOriginalRequest: true,
                        newVariables: originalVariables,
                    };
                }

                const now = new Date();

                let feeProductType: AppointmentFeeProductType | null = null;

                if (
                    settingsById.chargeVeryLateCancelFee.enabled &&
                    now >
                        subHours(
                            start,
                            settingsById.chargeVeryLateCancelFee.hours,
                        )
                ) {
                    feeProductType = 'veryLateCancel';
                } else if (
                    settingsById.chargeLateCancelFee.enabled &&
                    now >
                        subHours(start, settingsById.chargeLateCancelFee.hours)
                ) {
                    feeProductType = 'cancel';
                }

                if (feeProductType) {
                    await triggerChargeFeeProductType(
                        feeProductType,
                        appointment,
                        originalQuery,
                        originalVariables,
                    );
                    return {
                        runOriginalRequest: false,
                        newVariables: originalVariables,
                    };
                }

                console.info('do not intercept - no fee rules matched');

                return {
                    runOriginalRequest: true,
                    newVariables: originalVariables,
                };
            } catch (error) {
                console.error(
                    'useLateCancelReschedule/handleDeleteRequest error: ',
                    error,
                );

                // execute original request on error
                return {
                    runOriginalRequest: true,
                    newVariables: originalVariables,
                };
            }
        },
        [
            getAppointmentAndCheckBasics,
            isProcedure,
            settingsById.chargeLateCancelFee.enabled,
            settingsById.chargeLateCancelFee.hours,
            settingsById.chargeVeryLateCancelFee.enabled,
            settingsById.chargeVeryLateCancelFee.hours,
            triggerChargeFeeProductType,
        ],
    );

    const handleUpdateRequest = React.useCallback(
        async <
            Variables extends Record<string, unknown> = Record<string, unknown>,
        >(
            appointmentId: string,
            originalQuery: string,
            originalVariables: Variables,
        ): Promise<InterceptorResponse<Variables>> => {
            try {
                const newStartS: string | null =
                    (
                        originalVariables as unknown as RootMutationTypeUpdateAppointmentArgs
                    )?.input?.start ?? null;

                if (!newStartS) {
                    console.info('do not intercept: no start date');

                    // no start date, so we'll just execute the original request
                    return {
                        runOriginalRequest: true,
                        newVariables: originalVariables,
                    };
                }

                const newStart = new Date(newStartS);

                const { appointment, runOriginalRequest, start } =
                    await getAppointmentAndCheckBasics(appointmentId);

                const procedure = appointment && isProcedure(appointment);

                if (
                    runOriginalRequest ||
                    !appointment ||
                    !appointment.patient ||
                    procedure
                ) {
                    console.info(
                        `do not intercept: runOriginalRequest=${runOriginalRequest}, !appointment=${!appointment}, !appointment.patient=${!appointment?.patient}, procedure=${procedure}`,
                    );

                    return {
                        runOriginalRequest: true,
                        newVariables: originalVariables,
                    };
                }

                const now = new Date();

                if (start.getTime() === newStart.getTime()) {
                    console.info(
                        'do not intercept: start date has not changed',
                    );

                    // start date didn't change, so we'll just execute the original request
                    return {
                        runOriginalRequest: true,
                        newVariables: originalVariables,
                    };
                }

                let feeProductType: AppointmentFeeProductType | null = null;

                if (
                    settingsById.chargeVeryLateRescheduleFee.enabled &&
                    now >
                        subHours(
                            start,
                            settingsById.chargeVeryLateRescheduleFee.hours,
                        )
                ) {
                    feeProductType = 'veryLateReschedule';
                } else if (
                    settingsById.chargeLateRescheduleFee.enabled &&
                    now >
                        subHours(
                            start,
                            settingsById.chargeLateRescheduleFee.hours,
                        )
                ) {
                    feeProductType = 'reschedule';
                }

                if (feeProductType) {
                    await triggerChargeFeeProductType(
                        feeProductType,
                        appointment,
                        originalQuery,
                        originalVariables,
                    );
                    return {
                        runOriginalRequest: false,
                        newVariables: originalVariables,
                    };
                }

                console.info('do not intercept - no fee rules matched');

                return {
                    runOriginalRequest: true,
                    newVariables: originalVariables,
                };
            } catch (error) {
                console.error(
                    'useLateCancelReschedule/handleUpdateRequest error: ',
                    error,
                );

                // execute original request on error
                return {
                    runOriginalRequest: true,
                    newVariables: originalVariables,
                };
            }
        },
        [
            getAppointmentAndCheckBasics,
            isProcedure,
            settingsById.chargeLateRescheduleFee.enabled,
            settingsById.chargeLateRescheduleFee.hours,
            settingsById.chargeVeryLateRescheduleFee.enabled,
            settingsById.chargeVeryLateRescheduleFee.hours,
            triggerChargeFeeProductType,
        ],
    );

    const onUpdateRequest = React.useCallback(
        async ({
            query,
            variables,
        }: InterceptorPayload<RootMutationTypeUpdateAppointmentArgs>): Promise<
            InterceptorResponse<RootMutationTypeUpdateAppointmentArgs>
        > => {
            if (vetspireSettings.interceptCancelReschedule) {
                await ignoreAlerts(
                    ['Error occurred. Appointment not updated.'],
                    5000,
                );
                closeModal();

                return handleUpdateRequest(variables.id, query, variables);
            }
            return {
                runOriginalRequest: true,
                newVariables: variables,
            };
        },
        [handleUpdateRequest, vetspireSettings.interceptCancelReschedule],
    );

    const onDeleteRequest = React.useCallback(
        async ({
            query,
            variables,
        }: InterceptorPayload<RootMutationTypeDeleteAppointmentArgs>): Promise<
            InterceptorResponse<RootMutationTypeDeleteAppointmentArgs>
        > => {
            if (vetspireSettings.interceptCancelReschedule) {
                closeModal();

                return handleDeleteRequest(variables.id, query, variables);
            }
            return {
                runOriginalRequest: true,
                newVariables: variables,
            };
        },
        [handleDeleteRequest, vetspireSettings.interceptCancelReschedule],
    );

    useRequestInterceptor<RootMutationTypeDeleteAppointmentArgs>(
        DELETE_APPOINTMENT_OPERATION_NAME,
        onDeleteRequest,
    );

    useRequestInterceptor<RootMutationTypeDeleteAppointmentArgs>(
        DELETE_APPT_OPERATION_NAME,
        onDeleteRequest,
    );

    useRequestInterceptor<RootMutationTypeUpdateAppointmentArgs>(
        UPDATE_APPOINTMENT_OPERATION_NAME,
        onUpdateRequest,
    );

    useRequestInterceptor<RootMutationTypeUpdateAppointmentArgs>(
        UPDATE_EVENT_LOCATION_OPERATION_NAME,
        onUpdateRequest,
    );
}
