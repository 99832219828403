import * as React from 'react';
import classnames from 'classnames';

import {
    DatePicker,
    LocalizationProvider,
    TimePicker,
} from '@mui/x-date-pickers';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import {
    ClientDigitalWalkIn,
    CreateAppointmentFromDigitalWalkInArguments,
} from '@bondvet/types/digitalWalkIns';

import useTranslate from 'hooks/useTranslate';

import WalkInDetails from '../WalkInDetails';
import { PartialVetspireAppointmentType } from '../../api';

import styles from './ArrivedDialog.module.scss';

type ArrivedDialogProps = {
    walkIn: ClientDigitalWalkIn;
    onClose: (refresh?: boolean) => void;
    handleSubmit: (
        variables: CreateAppointmentFromDigitalWalkInArguments,
    ) => void;
    status: { loading: boolean; error: string | null };
    appointmentTypes: PartialVetspireAppointmentType[];
    defaultDuration?: number;
};

type FormValues = Omit<CreateAppointmentFromDigitalWalkInArguments, 'start'> & {
    start: Date | null;
};

const ArrivedDialog: React.FunctionComponent<ArrivedDialogProps> = ({
    onClose,
    walkIn,
    handleSubmit,
    appointmentTypes,
    status,
    defaultDuration = 30,
}) => {
    const translate = useTranslate();

    const [formValues, setFormValues] = React.useState<FormValues>({
        digitalWalkInId: walkIn.id,
        reasonId: walkIn.reasonId,
        start: walkIn.date.toDate(),
        duration: defaultDuration,
        additionalNotes: '',
    });

    const disabled =
        !formValues.start ||
        !formValues.duration ||
        !formValues.reasonId ||
        status.loading;

    return (
        <Dialog open onClose={() => onClose()}>
            <DialogTitle className={styles.title}>
                {translate(
                    'vetspireExtension.digitalWalkIns.arrivedModal.title',
                )}
                <IconButton
                    onClick={() => onClose()}
                    className={styles.closeIcon}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={styles.content}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <div className={styles.infoBlock}>
                        <WalkInDetails walkInData={walkIn} />
                    </div>
                    <form className={styles.form}>
                        <div className={styles.labelAndInput}>
                            <div className={styles.label}>
                                {translate(
                                    'vetspireExtension.digitalWalkIns.arrivedModal.fields.reason',
                                )}
                            </div>
                            <Select
                                id="reasonDropdown"
                                value={formValues.reasonId}
                                sx={{ width: '100%' }}
                                onChange={(e) => {
                                    setFormValues({
                                        ...formValues,
                                        reasonId: e.target.value,
                                        duration:
                                            appointmentTypes.find(
                                                (a) => a.id === e.target.value,
                                            )?.duration || formValues.duration,
                                    });
                                }}
                            >
                                {appointmentTypes.map((appointmentType) => (
                                    <MenuItem
                                        key={appointmentType.id}
                                        value={appointmentType.id}
                                    >
                                        {appointmentType.name || ''}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div className={styles.labelAndInput}>
                            <div className={styles.label}>
                                {translate(
                                    'vetspireExtension.digitalWalkIns.arrivedModal.fields.date',
                                )}
                            </div>
                            <DatePicker
                                className={styles.datePicker}
                                value={formValues.start}
                                onChange={(selectedDate) => {
                                    if (selectedDate) {
                                        const newStart = new Date(selectedDate);
                                        if (formValues.start) {
                                            newStart.setTime(
                                                new Date(
                                                    formValues.start,
                                                ).getTime(),
                                            );
                                        }
                                        setFormValues({
                                            ...formValues,
                                            start: newStart,
                                        });
                                    }
                                }}
                            />
                        </div>
                        <div className={styles.timeInfo}>
                            <div className={styles.labelAndInput}>
                                <div className={styles.labelAndInput}>
                                    <div className={styles.label}>
                                        {translate(
                                            'vetspireExtension.digitalWalkIns.arrivedModal.fields.time',
                                        )}
                                    </div>
                                    <TimePicker
                                        className={styles.timePicker}
                                        value={formValues.start}
                                        onChange={(selectedTime) => {
                                            if (selectedTime) {
                                                const newStart = new Date(
                                                    formValues.start ?? '',
                                                );
                                                newStart.setTime(
                                                    selectedTime.getTime(),
                                                );
                                                setFormValues({
                                                    ...formValues,
                                                    start: newStart,
                                                });
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={styles.labelAndInput}>
                                <div className={styles.label}>
                                    {translate(
                                        'vetspireExtension.digitalWalkIns.arrivedModal.fields.duration',
                                    )}
                                </div>
                                <div className={styles.durationWithMinutes}>
                                    <TextField
                                        name="duration"
                                        type="number"
                                        className={styles.duration}
                                        value={formValues.duration}
                                        onChange={(e) => {
                                            const numberValue = parseInt(
                                                e.target.value,
                                                10,
                                            );
                                            setFormValues({
                                                ...formValues,
                                                duration: numberValue ?? '',
                                            });
                                        }}
                                    />

                                    <div className={styles.minutes}>
                                        {translate(
                                            'vetspireExtension.digitalWalkIns.arrivedModal.fields.minutes',
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.labelAndInput}>
                            <div className={styles.label}>
                                {translate(
                                    'vetspireExtension.digitalWalkIns.arrivedModal.fields.notes',
                                )}
                            </div>
                            <TextField
                                id={'notes'}
                                className={styles.notes}
                                multiline
                                variant="outlined"
                                value={formValues.additionalNotes}
                                onChange={(updatedNotes) =>
                                    setFormValues({
                                        ...formValues,
                                        additionalNotes:
                                            updatedNotes.target.value,
                                    })
                                }
                            />
                        </div>
                    </form>
                </LocalizationProvider>
                <DialogActions className={styles.actionButtons}>
                    <Button
                        color="primary"
                        variant="text"
                        className={styles.cancel}
                        onClick={() => onClose()}
                    >
                        {translate(
                            'vetspireExtension.digitalWalkIns.arrivedModal.cancel',
                        )}
                    </Button>
                    <Button
                        type="submit"
                        disabled={disabled}
                        onClick={() => {
                            if (
                                formValues.start &&
                                formValues.duration &&
                                formValues.reasonId
                            ) {
                                handleSubmit({
                                    ...formValues,
                                    start: formValues.start.toISOString(),
                                });
                            }
                        }}
                        className={classnames(styles.submit, {
                            [styles.disabled]: disabled,
                        })}
                    >
                        {translate(
                            'vetspireExtension.digitalWalkIns.arrivedModal.submit',
                        )}
                    </Button>
                </DialogActions>
                {status.error && (
                    <div className={styles.error}>{status.error}</div>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default ArrivedDialog;
