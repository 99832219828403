import { gql } from '@apollo/client';
import type { OperationResult } from '@bondvet/types';
import { AppointmentType } from '@bondvet/types/generated/vetspire';

export const digitalWalkInsEnabledQuery = gql`
    query digitalWalkInsEnabledQuery($vetspireLocationId: String!) {
        digitalWalkInsEnabled(vetspireLocationId: $vetspireLocationId)
    }
`;

export interface DigitalWalkInsEnabledQueryResult {
    digitalWalkInsEnabled: boolean;
}

export interface DigitalWalkInsEnabledQueryVariables {
    vetspireLocationId: string;
}

export const updateDigitalWalkInStatusMutation = gql`
    mutation updateDigitalWalkInStatus(
        $digitalWalkInId: UUID!
        $hasArrived: Boolean!
        $isNoShow: Boolean!
        $isDeleted: Boolean!
    ) {
        updateDigitalWalkInStatus(
            digitalWalkInId: $digitalWalkInId
            hasArrived: $hasArrived
            isNoShow: $isNoShow
            isDeleted: $isDeleted
        ) {
            success
            error
        }
    }
`;

export interface UpdateDigitalWalKInStatusResult {
    updateDigitalWalkInStatus: OperationResult;
}

export const createAppointmentFromDigitalWalkInMutation = gql`
    mutation createAppointmentFromDigitalWalkIn(
        $digitalWalkInId: UUID!
        $reasonId: String!
        $start: DateTime!
        $duration: Int!
        $additionalNotes: String
    ) {
        createAppointmentFromDigitalWalkIn(
            digitalWalkInId: $digitalWalkInId
            reasonId: $reasonId
            start: $start
            duration: $duration
            additionalNotes: $additionalNotes
        ) {
            success
            error
        }
    }
`;

export interface CreateAppointmentFromDigitalWalkInVariables {
    digitalWalkInId: string;
    reasonId: string;
    start: Date;
    duration: number;
    additionalNotes: string;
}

export interface CreateAppointmentFromDigitalWalkInResult {
    createAppointmentFromDigitalWalkIn: OperationResult;
}

export const vetspireAppointmentTypesQuery = gql`
    query appointmentTypes {
        appointmentTypes {
            id
            name
            duration
        }
    }
`;

export type PartialVetspireAppointmentType = Pick<
    AppointmentType,
    'id' | 'name' | 'duration'
>;
export interface VetspireAppointmentTypesQueryResult {
    appointmentTypes?: PartialVetspireAppointmentType[];
}
