import * as React from 'react';
import { AppointmentFeeProductType } from '@bondvet/types/noShows';
import useLocationId from 'hooks/useLocationId';
import { useMutation, useQuery } from '@apollo/client';
import {
    chargeFeeProduct,
    ChargeFeeProductResult,
    ChargeFeeProductVariables,
} from 'api/clients/mutations';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import classNames from 'classnames';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import useTranslate from 'hooks/useTranslate';
import CircularProgress from '@mui/material/CircularProgress';
import { LocationsSelect } from 'components/LookupSelect';
import {
    Location,
    locationsQuery,
    LocationsQueryResult,
} from 'api/locations/queries';
import useClientAndPatientId from 'hooks/useClientAndPatientId';
import useAnyPatientId from 'hooks/useAnyPatientId';
import { openInvoice, Page } from 'lib/vetspireActions';
import { useAnalytics } from 'hooks/useAnalytics';
import useFeeProducts from 'hooks/useFeeProducts';
import { formatAmountAsUSD } from '../../util';
import styles from './ChargeFeeProduct.module.scss';

type ChargeFeeProductProps = {
    last4: import('@stripe/stripe-js').Card['last4'];
    feeProductType: AppointmentFeeProductType;
    onClose: () => void;
};

export default function ChargeFeeProduct({
    last4,
    feeProductType,
    onClose,
}: ChargeFeeProductProps): React.ReactElement {
    const analytics = useAnalytics();
    const [selectedLocationId, setSelectedLocationId] = React.useState<
        string | null
    >(null);
    const [runChargeFeeProductMutation, { loading, data }] = useMutation<
        ChargeFeeProductResult,
        ChargeFeeProductVariables
    >(chargeFeeProduct, {
        context: { clientName: GRAPHQL_CLIENT_NAMES.creditCards },
    });
    const [error, setError] = React.useState('');

    const { clientId } = useClientAndPatientId();
    const patientId = useAnyPatientId(clientId);

    React.useEffect(() => {
        if (loading) {
            setError('');
        } else if (data?.chargeFeeProduct.success) {
            setError('');
            const { invoiceId } = data.chargeFeeProduct;
            if (invoiceId && patientId && clientId) {
                openInvoice(clientId, patientId, invoiceId);
            }
        } else if (data?.chargeFeeProduct.error) {
            setError(data.chargeFeeProduct.error);
        }
    }, [loading, data, patientId, clientId]);

    const handleSubmit = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();

            if (clientId && selectedLocationId) {
                runChargeFeeProductMutation({
                    variables: {
                        clientId,
                        locationId: selectedLocationId,
                        feeProductType,
                    },
                }).then();

                analytics.trackEvent(
                    Page.clientDetails,
                    `charge_fee_product_${feeProductType}`,
                );
            }
        },
        [
            runChargeFeeProductMutation,
            selectedLocationId,
            clientId,
            feeProductType,
            analytics,
        ],
    );

    const translate = useTranslate();
    const locationsQueryResult = useQuery<LocationsQueryResult>(
        locationsQuery,
        {
            fetchPolicy: 'cache-and-network',
        },
    );
    const [selectedLocations, setSelectedLocations] = React.useState<
        Location[]
    >([]);

    const feeProducts = useFeeProducts(selectedLocations[0]?.id);

    const onChangeLocation = (locations: Location[]) => {
        setSelectedLocations(locations);
    };

    const handleNext = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setSelectedLocationId(selectedLocations[0]?.id);
    };

    const defaultLocationId = useLocationId();
    React.useEffect(() => {
        if (
            defaultLocationId !== '' &&
            selectedLocations.length === 0 &&
            locationsQueryResult?.data?.locations.length
        ) {
            const location = locationsQueryResult?.data?.locations.find(
                ({ id }) => id === defaultLocationId,
            );

            if (location) {
                setSelectedLocations([location]);
            }
        }
    }, [defaultLocationId, locationsQueryResult, selectedLocations]);

    const showLocationSelection =
        !loading &&
        !error &&
        !data?.chargeFeeProduct.success &&
        selectedLocationId === null;
    const showConfirmation =
        !loading &&
        !error &&
        !data?.chargeFeeProduct.success &&
        selectedLocationId !== null;

    return (
        <Dialog
            open
            classes={{
                paper: classNames(styles.paper, {
                    [styles.canOverflow]: showLocationSelection,
                }),
            }}
        >
            {loading && (
                <DialogContent
                    className={classNames(styles.status, styles.loading)}
                >
                    <CircularProgress />
                    <DialogContentText>
                        <div>
                            {translate(
                                'vetspireExtension.chargeFeeProduct.processing',
                            )}
                        </div>
                        <div>
                            {translate(
                                'vetspireExtension.chargeFeeProduct.processingNote',
                            )}
                        </div>
                    </DialogContentText>
                </DialogContent>
            )}
            {!loading && error && (
                <DialogContent
                    className={classNames(styles.error, styles.status)}
                >
                    <CancelIcon />
                    <DialogContentText>
                        <strong>
                            {translate(
                                'vetspireExtension.chargeFeeProduct.failed',
                            )}
                        </strong>
                        {error}
                    </DialogContentText>

                    <DialogActions>
                        <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            {translate(
                                'vetspireExtension.chargeFeeProduct.retry',
                            )}
                        </Button>
                        <Button type="button" onClick={onClose}>
                            {translate(
                                'vetspireExtension.chargeFeeProduct.close',
                            )}
                        </Button>
                    </DialogActions>
                </DialogContent>
            )}

            {!loading && data?.chargeFeeProduct.success && (
                <DialogContent
                    className={classNames(styles.status, styles.success)}
                >
                    <CheckIcon />
                    {translate('vetspireExtension.chargeFeeProduct.success')}
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={onClose}
                    >
                        {translate('vetspireExtension.chargeFeeProduct.close')}
                    </Button>
                </DialogContent>
            )}

            {showLocationSelection && (
                <>
                    <DialogTitle>
                        {translate(
                            'vetspireExtension.chargeFeeProduct.selectLocation.title',
                        )}
                    </DialogTitle>
                    <DialogContent className={styles.canOverflow}>
                        <LocationsSelect
                            isMulti={false}
                            locationsQueryResult={locationsQueryResult}
                            selectedLocations={selectedLocations}
                            onChange={onChangeLocation}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button type="button" onClick={onClose}>
                            {translate(
                                'vetspireExtension.chargeFeeProduct.selectLocation.cancel',
                            )}
                        </Button>
                        <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            onClick={handleNext}
                            disabled={loading || selectedLocations.length === 0}
                        >
                            {translate(
                                'vetspireExtension.chargeFeeProduct.selectLocation.next',
                            )}
                        </Button>
                    </DialogActions>
                </>
            )}
            {showConfirmation && (
                <>
                    <DialogTitle>
                        {translate('vetspireExtension.chargeFeeProduct.title')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {translate(
                                'vetspireExtension.chargeFeeProduct.content',
                                {
                                    card: last4,
                                    amount: formatAmountAsUSD(
                                        feeProducts.pricesByType[
                                            feeProductType
                                        ] * 100,
                                    ),
                                    fee: translate(
                                        `vetspireExtension.chargeFeeProduct.feeType.${feeProductType}`,
                                    ),
                                },
                            )}
                        </DialogContentText>
                        <DialogActions>
                            <Button type="button" onClick={onClose}>
                                {translate(
                                    'vetspireExtension.chargeFeeProduct.cancel',
                                )}
                            </Button>
                            <Button
                                type="button"
                                color="primary"
                                variant="contained"
                                onClick={handleSubmit}
                                disabled={loading}
                            >
                                {translate(
                                    'vetspireExtension.chargeFeeProduct.submit',
                                )}
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </>
            )}
        </Dialog>
    );
}
