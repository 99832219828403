import { gql } from '@apollo/client';
import {
    GraphQLGeneralSurgeryReferralQuestion,
    GraphQLSurgeryAppointment,
    SurgeryReferral,
    SurgeryType,
} from '@bondvet/types/surgeryReferrals';
import { Details } from 'api/clients/queries';
import { Location } from '@bondvet/types/locations';
import {
    GeneralSurgeryReferralQuestionFragment,
    SurgeryReferralFragment,
} from './fragments';

export const surgeryTypes = gql`
    query surgeryTypes {
        surgeryTypes {
            _id
            name
            notesRequired
            isSpecialtySurgery
            questions {
                __typename
                type
                label
                required
                ... on SurgeryReferralRadiosQuestion {
                    options
                }
                ... on SurgeryReferralCheckboxesQuestion {
                    options
                }
                ... on SurgeryReferralDropdownQuestion {
                    options
                }
            }
        }
    }
`;

export type SurgeryTypesQuery = {
    surgeryTypes: ReadonlyArray<SurgeryType>;
};

export const generalQuestions = gql`
    ${GeneralSurgeryReferralQuestionFragment}
    query generalQuestions {
        generalSurgeryReferralQuestions {
            ...GeneralSurgeryReferralQuestionFragment
        }
    }
`;

export type GeneralQuestionsQuery = {
    generalSurgeryReferralQuestions: ReadonlyArray<GraphQLGeneralSurgeryReferralQuestion>;
};

export const surgeryReferrals = gql`
    ${SurgeryReferralFragment}
    query surgeryReferrals($patientId: ID) {
        surgeryReferrals(patientId: $patientId) {
            ...SurgeryReferralFragment
        }
    }
`;

export type GraphQLSurgeryReferral = Pick<
    SurgeryReferral,
    '_id' | 'clientId' | 'patientId' | 'linkedInvoices' | 'notes'
> & {
    provider: Details;
    surgeryType: Pick<SurgeryType, '_id' | 'name' | 'notesRequired'>;
};

export type SurgeryReferralsQuery = {
    surgeryReferrals: ReadonlyArray<GraphQLSurgeryReferral>;
};

export const surgeryAppointments = gql`
    fragment OrderData on SurgeryOrder {
        id
        invoiced
        patients {
            id
        }
    }
    query surgeryAppointments($locationId: String, $from: Date, $to: Date) {
        surgeryAppointments(locationId: $locationId, from: $from, to: $to) {
            id
            hidden
            schedule {
                id
                name
            }
            start
            status
            patient {
                id
                name
                client {
                    id
                    name
                }
            }
            location {
                id
                name
                timezone
            }
            type {
                id
                name
            }
            linkedReferrals {
                _id
            }
            invoices {
                ...OrderData
            }
        }
    }
`;

export type SurgeryAppointmentsQuery = {
    surgeryAppointments: ReadonlyArray<GraphQLSurgeryAppointment>;
};

export type SurgeryAppointmentsQueryVariables = {
    locationId?: string;
    from?: string;
    to?: string;
};

export const surgeryLocationsQuery = gql`
    query {
        locations {
            _id
            name
            showInSurgeryReferralsSelect
            performsSpecialtySurgeries
            _vetspire {
                id
            }
        }
    }
`;

export type SurgeryLocation = Pick<
    Location,
    | '_id'
    | 'name'
    | 'showInSurgeryReferralsSelect'
    | 'performsSpecialtySurgeries'
> & {
    _vetspire?: null | { id: string };
};

export type SurgeryLocationsQuery = {
    locations: ReadonlyArray<SurgeryLocation>;
};
