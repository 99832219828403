import type { OperationResult } from '@bondvet/types';
import type { SurgeryReferralInput } from '@bondvet/types/surgeryReferrals';
import { gql } from '@apollo/client';
import { GraphQLSurgeryReferral } from './queries';
import { SurgeryReferralFragment } from './fragments';

export type SendSurgeryRecommendationResult = {
    sendSurgeryRecommendation: OperationResult;
};

export type SendSurgeryRecommendationVariables = {
    input: SurgeryReferralInput;
};

export const sendSurgeryRecommendation = gql`
    mutation sendSurgeryRecommendation($input: SurgeryReferralInput!) {
        sendSurgeryRecommendation(input: $input) {
            success
            error
        }
    }
`;

export const linkSurgeryReferral = gql`
    ${SurgeryReferralFragment}
    mutation linkSurgeryReferral($invoiceId: String!, $referralId: String!) {
        linkSurgeryReferral(invoiceId: $invoiceId, referralId: $referralId) {
            ...SurgeryReferralFragment
        }
    }
`;

export type LinkSurgeryReferralResult = {
    linkSurgeryReferral: GraphQLSurgeryReferral;
};

export type LinkSurgeryReferralVariables = {
    invoiceId: string;
    referralId: string;
};

export const unlinkSurgeryReferral = gql`
    ${SurgeryReferralFragment}
    mutation unlinkSurgeryReferral($referralId: String!) {
        unlinkSurgeryReferral(referralId: $referralId) {
            ...SurgeryReferralFragment
        }
    }
`;

export type UnlinkSurgeryReferralResult = {
    unlinkSurgeryReferral: GraphQLSurgeryReferral;
};

export type UnlinkSurgeryReferralVariables = {
    referralId: string;
};

export const hideAppointment = gql`
    mutation hideAppointmnet($appointmentId: String!) {
        hideSurgeryAppointment(appointmentId: $appointmentId) {
            success
            error
        }
    }
`;

export type HideAppointmentResult = {
    hideSurgeryAppointment: OperationResult;
};

export type HideAppointmentVariables = {
    appointmentId: string;
};

export const scheduleConsultationMutation = gql`
    mutation createConsultationAppointment(
        $input: CreateConsultationAppointmentInput!
    ) {
        createConsultationAppointment(input: $input) {
            success
            error
        }
    }
`;
