import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import * as React from 'react';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import classnames from 'classnames';
import { format, isAfter, addDays } from 'date-fns';
import styles from './WeekSelect.module.scss';

interface WeekSelectProps {
    week: Date;
    onChange: (newDay: Date) => void;
    mode?: 'default' | 'picker';
    weekStartFormat?: string;
    weekEndFormat?: string;
    disablePast?: boolean;
}

const WeekSelect: React.FunctionComponent<WeekSelectProps> = ({
    week,
    onChange,
    mode = 'default',
    weekStartFormat = 'MM/D/YY',
    weekEndFormat = 'MM/D/YY',
    disablePast = false,
}) => {
    const [showDatePicker, setShowDatePicker] = React.useState(false);

    const toggleDatePicker = () => {
        setShowDatePicker(!showDatePicker);
    };

    const handleChange = (value: Date) => {
        setShowDatePicker(false);
        onChange(value);
    };

    const showPast = React.useMemo(() => {
        if (disablePast) {
            return isAfter(week, new Date());
        }
        return true;
    }, [disablePast, week]);

    const content = (
        <>
            {mode === 'default' && showPast && (
                <button
                    type="button"
                    className={styles.buttonBack}
                    onClick={() => {
                        onChange(addDays(week, -7));
                    }}
                >
                    <ArrowBackIosIcon fontSize="small" />
                </button>
            )}
            {!showPast && <div />}
            <div className={styles.date}>
                {format(week, weekStartFormat)} -{' '}
                {format(addDays(week, 6), weekEndFormat)}
            </div>
            {mode === 'default' && (
                <button
                    type="button"
                    className={styles.buttonForward}
                    onClick={() => {
                        onChange(addDays(week, 7));
                    }}
                >
                    <ArrowBackIosIcon fontSize="small" />
                </button>
            )}
            {mode === 'picker' && <CalendarTodayIcon fontSize="small" />}
        </>
    );

    if (mode === 'picker') {
        return (
            <div className={styles.containerWrapper}>
                <button
                    onClick={toggleDatePicker}
                    className={classnames(styles.container, styles.picker)}
                >
                    {content}
                </button>
                {showDatePicker && (
                    <div className={styles.calendar}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateCalendar
                                defaultValue={week}
                                onChange={handleChange}
                            />
                        </LocalizationProvider>
                    </div>
                )}
            </div>
        );
    }
    return <div className={styles.container}>{content}</div>;
};

export default WeekSelect;
