import * as React from 'react';
import classnames from 'classnames';
import useTranslate from 'hooks/useTranslate';
import { gql, useQuery } from '@apollo/client';
import {
    AppointmentFeeProductType,
    FEE_PRODUCT_TYPE_NO_SHOW,
    FEE_PRODUCT_TYPE_CANCEL,
    FEE_PRODUCT_TYPE_RESCHEDULE,
    FEE_PRODUCT_TYPE_VERY_LATE_CANCEL,
    FEE_PRODUCT_TYPE_VERY_LATE_RESCHEDULE,
} from '@bondvet/types/noShows';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import { CircularProgress } from '@mui/material';
import styles from './LifetimeStats.module.scss';
import useLifetimeStats from '../../hooks/useLifetimeStats';
import LifetimeStatsModal from '../LifetimeStatsModal';

export type LifetimeStatsProps = {
    clientId: string;
    className?: string;
    noHeader?: boolean;
    hideEmpty?: boolean;
    noModal?: boolean;
};

const bookingFeeSettingsQuery = gql`
    query bookingFeeSettingsQuery {
        bookingFeeSettings {
            id
            enabled
        }
    }
`;

type BookingFeeSettingsQueryResult = {
    bookingFeeSettings: ReadonlyArray<{
        id: AppointmentFeeProductType;
        enabled: boolean;
    }>;
};

function LifetimeStats({
    clientId,
    className,
    noHeader = false,
    hideEmpty = false,
    noModal = false,
}: LifetimeStatsProps): React.ReactElement {
    const translate = useTranslate();
    const {
        noShows,
        lateCancels,
        lateReschedules,
        veryLateCancels,
        veryLateReschedules,
    } = useLifetimeStats(clientId);

    const { data: bookingFeeSettingData, loading } =
        useQuery<BookingFeeSettingsQueryResult>(bookingFeeSettingsQuery, {
            fetchPolicy: 'network-only',
            context: { clientName: GRAPHQL_CLIENT_NAMES.default },
            variables: { clientId },
        });

    const bookingFeeSettings = React.useMemo(() => {
        return bookingFeeSettingData?.bookingFeeSettings.reduce(
            (acc, { id, enabled }) => ({ ...acc, [id]: enabled }),
            {} as Partial<{ [id in AppointmentFeeProductType]: boolean }>,
        );
    }, [bookingFeeSettingData]);

    const [modalOpen, setModalOpen] =
        React.useState<AppointmentFeeProductType | null>(null);

    const modalAppointments = React.useMemo(() => {
        switch (modalOpen) {
            case FEE_PRODUCT_TYPE_NO_SHOW:
                return noShows.data;
            case FEE_PRODUCT_TYPE_CANCEL:
                return lateCancels.data;
            case FEE_PRODUCT_TYPE_RESCHEDULE:
                return lateReschedules.data;
            case FEE_PRODUCT_TYPE_VERY_LATE_CANCEL:
                return veryLateCancels.data;
            case FEE_PRODUCT_TYPE_VERY_LATE_RESCHEDULE:
                return veryLateReschedules.data;
            default:
                return [];
        }
    }, [
        lateCancels.data,
        lateReschedules.data,
        modalOpen,
        noShows.data,
        veryLateCancels.data,
        veryLateReschedules.data,
    ]);

    const showNoShows = !hideEmpty || noShows.count > 0;
    const showLateCancels = !hideEmpty || lateCancels.count > 0;
    const showLateReschedules = !hideEmpty || lateReschedules.count > 0;
    const showVeryLateCancels = !hideEmpty || veryLateCancels.count > 0;
    const showVeryLateReschedules = !hideEmpty || veryLateReschedules.count > 0;
    const hasContent =
        showNoShows ||
        showLateCancels ||
        showLateReschedules ||
        showVeryLateCancels ||
        showVeryLateReschedules;

    return loading ? (
        <CircularProgress />
    ) : (
        <>
            {!noHeader && (
                <header
                    className={classnames(styles.flyoutBlock, styles.first)}
                >
                    <span className={styles.flyoutTitle}>
                        {translate(
                            'vetspireExtension.clientDetails.payments.lifetimeStats.title',
                        )}
                    </span>
                </header>
            )}

            <div
                className={classnames(styles.statsContainer, className, {
                    [styles.hide]: !hasContent,
                    [styles.hideEmpty]: hideEmpty,
                })}
            >
                <button
                    className={classnames(styles.statsButton, {
                        [styles.enabled]: !noModal && noShows.count !== 0,
                        [styles.hide]: !showNoShows,
                    })}
                    onClick={() => setModalOpen('noShow')}
                    disabled={noModal || noShows.count === 0}
                >
                    <span className={styles.name}>
                        {translate(
                            'vetspireExtension.clientDetails.payments.lifetimeStats.noShow',
                        )}
                        :
                    </span>
                    <span className={styles.number}>{noShows.count}</span>
                </button>

                {bookingFeeSettings?.cancel && (
                    <button
                        className={classnames(styles.statsButton, {
                            [styles.enabled]:
                                !noModal && lateCancels.count !== 0,
                            [styles.hide]: !showLateCancels,
                        })}
                        onClick={() => setModalOpen('cancel')}
                        disabled={noModal || lateCancels.count === 0}
                    >
                        <span className={styles.name}>
                            {translate(
                                'vetspireExtension.clientDetails.payments.lifetimeStats.cancel',
                            )}
                            :
                        </span>
                        <span className={styles.number}>
                            {lateCancels.count}
                        </span>
                    </button>
                )}

                {bookingFeeSettings?.reschedule && (
                    <button
                        className={classnames(styles.statsButton, {
                            [styles.enabled]:
                                !noModal && lateReschedules.count !== 0,
                            [styles.hide]: !showLateReschedules,
                        })}
                        onClick={() => setModalOpen('reschedule')}
                        disabled={noModal || lateReschedules.count === 0}
                    >
                        <span className={styles.name}>
                            {translate(
                                'vetspireExtension.clientDetails.payments.lifetimeStats.reschedule',
                            )}
                            :
                        </span>
                        <span className={styles.number}>
                            {lateReschedules.count}
                        </span>
                    </button>
                )}

                {bookingFeeSettings?.veryLateCancel && (
                    <button
                        className={classnames(styles.statsButton, {
                            [styles.enabled]:
                                !noModal && veryLateCancels.count !== 0,
                            [styles.hide]: !showVeryLateCancels,
                        })}
                        onClick={() => setModalOpen('veryLateCancel')}
                        disabled={noModal || veryLateCancels.count === 0}
                    >
                        <span className={styles.name}>
                            {translate(
                                'vetspireExtension.clientDetails.payments.lifetimeStats.veryLateCancel',
                            )}
                            :
                        </span>
                        <span className={styles.number}>
                            {veryLateCancels.count}
                        </span>
                    </button>
                )}

                {bookingFeeSettings?.veryLateReschedule && (
                    <button
                        className={classnames(styles.statsButton, {
                            [styles.enabled]:
                                !noModal && veryLateReschedules.count !== 0,
                            [styles.hide]: !showVeryLateReschedules,
                        })}
                        onClick={() => setModalOpen('veryLateReschedule')}
                        disabled={noModal || veryLateReschedules.count === 0}
                    >
                        <span className={styles.name}>
                            {translate(
                                'vetspireExtension.clientDetails.payments.lifetimeStats.veryLateReschedule',
                            )}
                            :
                        </span>
                        <span className={styles.number}>
                            {veryLateReschedules.count}
                        </span>
                    </button>
                )}
            </div>
            {modalOpen && (
                <LifetimeStatsModal
                    type={modalOpen}
                    appointments={modalAppointments}
                    onClose={() => setModalOpen(null)}
                />
            )}
        </>
    );
}

export default LifetimeStats;
