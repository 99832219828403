import * as React from 'react';
import {
    MonetizationOn,
    Report,
    VisibilityOutlined,
} from '@mui/icons-material';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { SukiMode } from 'pages/Suki/types';
import useViewerSettings from 'hooks/useViewerSettings';
import { ReactComponent as AttachEmailIcon } from 'assets/attachEmail.svg';
import { Page } from 'lib/vetspireActions';
import useBondRoles from 'hooks/useBondRoles';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import VideocamIcon from '@mui/icons-material/Videocam';
import { ReactComponent as SukiIdleIcon } from 'assets/navSukiIdle.svg';
import { ReactComponent as SukiInProgressIcon } from 'assets/navSukiInProgress.svg';
import { ReactComponent as SukiPausedIcon } from 'assets/navSukiPaused.svg';
import { ReactComponent as PatientPlansIcon } from 'assets/navPatientPlans.svg';
import { ReactComponent as TravelCertificatesIcon } from 'assets/navTravelCertificates.svg';
import { ReactComponent as IntradayCapacityIcon } from 'assets/navIntradayCapacity.svg';
import { ReactComponent as DigitalWalkInsIcon } from 'assets/navDigitalWalkIns.svg';
import { PageDomain } from 'lib/types';
import { useQuery } from '@apollo/client';
import {
    extensionMenuBarSettings,
    MenuBarSettingsResult,
} from 'api/bond/queries';
import useShowBioQuestionnaire from 'hooks/useShowBioQuestionnaire';
import useLocationId from 'hooks/useLocationId';
import useBondQuery from 'hooks/useBondQuery';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import { ProviderRightValue } from '@bondvet/types/providers';

import {
    digitalWalkInsEnabledQuery,
    DigitalWalkInsEnabledQueryResult,
    DigitalWalkInsEnabledQueryVariables,
} from '../../DigitalWalkIns/api';

import styles from '../NavigationBar.module.scss';

export type MenuItem = {
    titleTag: string;
    icon: React.ReactElement;
    page: Page;
};

const useMenuList = (
    sukiMode: SukiMode,
): [MenuItem[], (items: MenuItem[]) => void] => {
    const bondRoles = useBondRoles();
    const viewerSettings = useViewerSettings();
    const locationId = useLocationId();

    const { data } = useQuery<MenuBarSettingsResult>(extensionMenuBarSettings, {
        context: { clientName: GRAPHQL_CLIENT_NAMES.default },
        skip: !viewerSettings.viewer?.id,
        variables: { userId: viewerSettings.viewer?.id },
    });

    const { data: digitalWalkInsData } = useBondQuery<
        DigitalWalkInsEnabledQueryResult,
        DigitalWalkInsEnabledQueryVariables
    >(digitalWalkInsEnabledQuery, {
        variables: { vetspireLocationId: locationId },
        fetchPolicy: 'cache-and-network',
    });

    const showBioQuestionnaire = useShowBioQuestionnaire();

    const [menuButtons, setMenuButtons] = React.useState<MenuItem[]>([]);

    React.useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const pageDomain = urlParams.get('pageDomain');

        const initialValues: (MenuItem | false)[] = [
            ([
                ProviderRightValue.enabled_ownRecords,
                ProviderRightValue.enabled_allRecords,
            ].includes(
                viewerSettings.rights?.vetspireExtension_providerPayment ||
                    ProviderRightValue.disabled,
            ) ||
                viewerSettings.rights?.vetspireExtension_gainsharing ===
                    ProviderRightValue.enabled) && {
                titleTag: 'vetspireExtension.navigation.providerPayment',
                icon: <MonetizationOn classes={{ root: styles.icon }} />,
                page: Page.providerPayment,
            },
            viewerSettings.rights?.vetspireExtension_bonusly ===
                ProviderRightValue.enabled && {
                titleTag: 'vetspireExtension.navigation.bonusly',
                icon: (
                    <EmojiEventsOutlinedIcon classes={{ root: styles.icon }} />
                ),
                page: Page.bonusly,
            },

            [
                ProviderRightValue.enabled_ownRecords,
                ProviderRightValue.enabled_allRecords,
            ].includes(
                viewerSettings.rights?.vetspireExtension_tasks ||
                    ProviderRightValue.disabled,
            ) && {
                titleTag: 'vetspireExtension.navigation.tasks',
                icon: <CheckBoxIcon classes={{ root: styles.icon }} />,
                page: Page.tasks,
            },

            viewerSettings.rights?.vetspireExtension_surgeryForms ===
                ProviderRightValue.enabled &&
                pageDomain === PageDomain.vetspire && {
                    titleTag: 'vetspireExtension.navigation.surgeryForms',
                    icon: (
                        <AssignmentOutlinedIcon
                            classes={{ root: styles.icon }}
                        />
                    ),
                    page: Page.surgeryForms,
                },

            [
                ProviderRightValue.enabled_ownRecords,
                ProviderRightValue.enabled_allRecords,
            ].includes(
                viewerSettings.rights?.vetspireExtension_encounters ||
                    ProviderRightValue.disabled,
            ) && {
                titleTag: 'vetspireExtension.navigation.encounters',
                icon: (
                    <AssignmentIndOutlinedIcon
                        classes={{ root: styles.icon }}
                    />
                ),
                page: Page.encounters,
            },

            viewerSettings.rights?.vetspireExtension_creditCards ===
                ProviderRightValue.enabled &&
                pageDomain === PageDomain.vetspire && {
                    titleTag: 'vetspireExtension.navigation.clientDetails',
                    icon: (
                        <AccountCircleOutlinedIcon
                            classes={{ root: styles.icon }}
                        />
                    ),
                    page: Page.clientDetails,
                },

            (viewerSettings.rights?.vetspireExtension_surgeryReferrals ===
                ProviderRightValue.enabled_allRecords ||
                viewerSettings.rights?.vetspireExtension_surgeryReferrals ===
                    ProviderRightValue.enabled_ownRecords) &&
                pageDomain === PageDomain.vetspire && {
                    titleTag: 'vetspireExtension.navigation.surgeryReferrals',
                    icon: (
                        <AssignmentOutlinedIcon
                            classes={{ root: styles.icon }}
                        />
                    ),
                    page: Page.surgeryReferrals,
                },

            viewerSettings.rights?.vetspireExtension_apptLog ===
                ProviderRightValue.enabled && {
                titleTag: 'vetspireExtension.navigation.apptLog',
                icon: <VisibilityOutlined classes={{ root: styles.icon }} />,
                page: Page.apptLog,
            },

            viewerSettings.rights?.vetspireExtension_infoPdf ===
                ProviderRightValue.enabled && {
                titleTag: 'vetspireExtension.navigation.infoPDF',
                icon: <AttachEmailIcon className={styles.icon} />,
                page: Page.infoPDF,
            },

            viewerSettings.rights?.vetspireExtension_externalReferrals ===
                ProviderRightValue.enabled && {
                titleTag: 'vetspireExtension.navigation.externalReferrals',
                icon: <AttachEmailIcon className={styles.icon} />,
                page: Page.externalReferrals,
            },

            viewerSettings.rights?.vetspireExtension_medicalMishaps ===
                ProviderRightValue.enabled && {
                titleTag: 'vetspireExtension.navigation.medicalMishaps',
                icon: <Report className={styles.icon} />,
                page: Page.medicalMishaps,
            },

            viewerSettings.rights?.vetspireExtension_telehealth ===
                ProviderRightValue.enabled && {
                titleTag: 'vetspireExtension.navigation.telehealth',
                icon: <VideocamIcon classes={{ root: styles.icon }} />,
                page: Page.telehealth,
            },
            viewerSettings.rights?.vetspireExtension_suki ===
                ProviderRightValue.enabled && {
                titleTag: 'vetspireExtension.navigation.suki',
                icon:
                    // eslint-disable-next-line no-nested-ternary
                    sukiMode === 'inProgress' ? (
                        <SukiInProgressIcon className={styles.sukiIcon} />
                    ) : sukiMode === 'paused' ? (
                        <SukiPausedIcon className={styles.sukiIcon} />
                    ) : (
                        <SukiIdleIcon className={styles.sukiIcon} />
                    ),
                page: Page.suki,
            },
            viewerSettings.rights?.vetspireExtension_patientPlans ===
                ProviderRightValue.enabled && {
                titleTag: 'vetspireExtension.navigation.patientPlans',
                icon: <PatientPlansIcon className={styles.icon} />,
                page: Page.patientPlans,
            },
            viewerSettings.rights?.vetspireExtension_travelCertificates ===
                ProviderRightValue.enabled && {
                titleTag: 'vetspireExtension.navigation.travelCertificates',
                icon: <TravelCertificatesIcon className={styles.icon} />,
                page: Page.travelCertificates,
            },
        ];
        const menuButtonsFiltered = initialValues.filter(
            (elem) => elem,
        ) as MenuItem[];

        const fixedButtons = [
            showBioQuestionnaire && {
                titleTag: 'vetspireExtension.navigation.questionnaire',
                icon: <div className={styles.emojiIcon}>{'\uD83D\uDC36'}</div>,
                page: Page.questionnaire,
            },
            digitalWalkInsData &&
                digitalWalkInsData.digitalWalkInsEnabled && {
                    titleTag: 'vetspireExtension.navigation.digitalWalkIns',
                    icon: <DigitalWalkInsIcon className={styles.icon} />,
                    page: Page.digitalWalkIns,
                },
            [
                ProviderRightValue.enabled_ownRecords,
                ProviderRightValue.enabled_allRecords,
            ].includes(
                viewerSettings.rights?.vetspireExtension_intradayCapacity ??
                    ProviderRightValue.disabled,
            ) && {
                titleTag: 'vetspireExtension.navigation.intradayCapacity',
                icon: <IntradayCapacityIcon className={styles.icon} />,
                page: Page.intradayCapacity,
            },
            viewerSettings.rights?.vetspireExtension_texting ===
                ProviderRightValue.enabled && {
                titleTag: 'vetspireExtension.navigation.texting',
                icon: <SmsOutlinedIcon className={styles.icon} />,
                page: Page.texting,
            },
        ].filter((elem) => elem) as MenuItem[];

        if (data?.extensionMenuBarSettings?.menuItemList) {
            setMenuButtons([
                ...fixedButtons,
                ...menuButtonsFiltered.sort(
                    (a, b) =>
                        data.extensionMenuBarSettings.menuItemList.indexOf(
                            a.page,
                        ) -
                        data.extensionMenuBarSettings.menuItemList.indexOf(
                            b.page,
                        ),
                ),
            ]);
        } else {
            setMenuButtons([...fixedButtons, ...menuButtonsFiltered]);
        }
    }, [
        digitalWalkInsData,
        viewerSettings.rights,
        bondRoles,
        data,
        sukiMode,
        showBioQuestionnaire,
    ]);

    return [menuButtons, setMenuButtons];
};

export default useMenuList;
