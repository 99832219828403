import * as React from 'react';
import Typography from '@mui/material/Typography';
import { gql } from '@apollo/client';
import {
    TimeSlotsWithProviderResult,
    TimeSlotsWithProvidersArguments,
} from '@bondvet/types/booking';
import { format, startOfWeek, addDays, startOfDay, isBefore } from 'date-fns';
import { tz } from '@date-fns/tz';

import { getFallbackTimezone } from 'lib/utils';
import useTranslate from 'hooks/useTranslate';
import useBookingQuery from 'hooks/useBookingQuery';
import useLocationId from 'hooks/useLocationId';
import useMoneyFormatter from 'hooks/useMoneyFormatter';
import CheckBox from 'components/CheckBox/CheckBox';
import WeekCalendar from 'components/WeekCalendar';
import classnames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import { CheckboxValues } from '../../types';
import styles from './ScheduleConsultation.module.scss';

type ScheduleConsultationProps = {
    surgeon: { id: string; name: string };
    checkboxValues: CheckboxValues;
    changeCheckboxValues: (newValues: Partial<CheckboxValues>) => void;
    selectedTime: string | null;
    changeSelectedTime: (time: string) => void;
    consultationFee: number;
};

const timeSlotsQuery = gql`
    query timeSlotsWithProviders(
        $vetspireLocationId: String!
        $from: DateString!
        $to: DateString!
    ) {
        timeSlotsWithProviders(
            vetspireLocationId: $vetspireLocationId
            from: $from
            to: $to
        ) {
            timezone
            slotsWithProviders {
                dateTime
                provider {
                    id
                    name
                }
            }
        }
    }
`;

export default function ScheduleConsultation({
    surgeon,
    checkboxValues,
    changeCheckboxValues,
    changeSelectedTime,
    consultationFee,
    selectedTime,
}: ScheduleConsultationProps): React.ReactElement {
    const vetspireLocationId = useLocationId();
    const translate = useTranslate();
    const moneyFormatter = useMoneyFormatter({
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const [week, setWeek] = React.useState<Date>(startOfWeek(new Date()));
    const [selectedDay, setSelectedDay] = React.useState<Date>(
        startOfDay(new Date()),
    );
    const handleSelectDay = (day: Date) => {
        setSelectedDay(day);
    };

    const variables = React.useMemo(
        () => ({
            from: format(week, 'yyyy-MM-dd'),
            to: format(addDays(week, 7), 'yyyy-MM-dd'),
            vetspireLocationId,
        }),
        [week, vetspireLocationId],
    );

    const { data, loading } = useBookingQuery<
        TimeSlotsWithProviderResult,
        TimeSlotsWithProvidersArguments
    >(timeSlotsQuery, {
        variables,
    });

    const freeSlots = React.useMemo(() => {
        if (!data?.timeSlotsWithProviders?.slotsWithProviders) {
            return {};
        }

        return data.timeSlotsWithProviders?.slotsWithProviders.reduce(
            (acc: { [key: string]: string[] }, value) => {
                const date = format(value.dateTime, 'yyyy-MM-dd', {
                    in: tz(
                        data?.timeSlotsWithProviders?.timezone ??
                            getFallbackTimezone(),
                    ),
                });

                if (value.provider.id !== surgeon.id) {
                    return acc;
                }

                return {
                    ...acc,
                    [date]: acc?.[date]
                        ? [...acc[date], value.dateTime.toString()]
                        : [value.dateTime.toString()],
                };
            },
            {} as { [key: string]: string[] },
        );
    }, [data?.timeSlotsWithProviders, surgeon.id]);

    const checkDateDisabled = React.useCallback(
        (day: Date) => {
            // check if day has any free slots
            return !freeSlots?.[format(day, 'yyyy-MM-dd')]?.length;
        },
        [freeSlots],
    );

    const selectedDaySlots = React.useMemo(() => {
        const formattedDate = format(selectedDay, 'yyyy-MM-dd');

        return freeSlots?.[formattedDate]
            ?.slice(0)
            ?.sort((a, b) => (isBefore(a, b) ? -1 : 1))
            ?.map((time) => (
                <li key={time}>
                    <button
                        className={classnames(styles.timeSlot, {
                            [styles.selected]: selectedTime === time,
                        })}
                        onClick={(e) => {
                            e.preventDefault();
                            changeSelectedTime(time);
                        }}
                    >
                        {format(time, 'hh:mm a', {
                            in: tz(
                                data?.timeSlotsWithProviders?.timezone ??
                                    getFallbackTimezone(),
                            ),
                        })}
                    </button>
                </li>
            ));
    }, [changeSelectedTime, data, freeSlots, selectedDay, selectedTime]);

    return (
        <div>
            <hr />
            <div>
                <Typography>
                    {translate(
                        'vetspireExtension.surgeryReferrals.scheduleConsultation.title',
                        {
                            name: surgeon.name,
                        },
                        { renderInnerHtml: true },
                    )}
                </Typography>
                <WeekCalendar
                    week={week}
                    setWeek={setWeek}
                    disablePast
                    selectedDay={selectedDay}
                    onSelectDay={handleSelectDay}
                    checkDayDisabled={checkDateDisabled}
                    disabledDayTooltipText={translate(
                        'vetspireExtension.surgeryReferrals.scheduleConsultation.noAvailability',
                    ).toString()}
                />
                {loading && (
                    <div className={styles.loading}>
                        <CircularProgress />
                    </div>
                )}
                <ul className={styles.slots}>{selectedDaySlots}</ul>
                {selectedTime && (
                    <div className={styles.disclaimer}>
                        <div className={styles.titleWrapper}>
                            <span className={styles.icon}>&#9995;</span>
                            <Typography className={styles.title}>
                                {translate(
                                    'vetspireExtension.surgeryReferrals.scheduleConsultation.disclaimer.title',
                                    undefined,
                                    { renderInnerHtml: true },
                                )}
                            </Typography>
                        </div>
                        <CheckBox
                            labelOrientation="after"
                            solid
                            label={translate(
                                'vetspireExtension.surgeryReferrals.scheduleConsultation.disclaimer.consultationOnly',
                                undefined,
                                { renderInnerHtml: true },
                            )}
                            value={checkboxValues.consultationOnly}
                            onChange={(consultationOnly) =>
                                changeCheckboxValues({
                                    consultationOnly,
                                })
                            }
                        />
                        <CheckBox
                            labelOrientation="after"
                            solid
                            label={translate(
                                'vetspireExtension.surgeryReferrals.scheduleConsultation.disclaimer.fee',
                                {
                                    fee: moneyFormatter.format(
                                        consultationFee / 100,
                                    ),
                                },
                                { renderInnerHtml: true },
                            )}
                            value={checkboxValues.fee}
                            onChange={(fee) =>
                                changeCheckboxValues({
                                    fee,
                                })
                            }
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
