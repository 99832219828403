import type { OperationResult } from '../common';

export interface RedirectData {
    type: number;
    destination: string;
}

export interface Redirect extends RedirectData {
    source: string;
}

export const TEAM_DATABASE_ROLES_COLLECTION_NAME = 'teamDatabase.roles';
export const TEAM_DATABASE_SETTINGS_COLLECTION_NAME = 'teamDatabase.settings';
export const TEAM_DATABASE_QUESTIONS_RTDB_PATH =
    'website/data/clinicTeamQuestions';

export type TeamDatabaseQuestionnaireType =
    | BioType.doctorQuestionnaire
    | BioType.generalQuestionnaire;

export type TeamDatabaseQuestion = {
    _id: string;
    orderId: number;
    employeeQuestion: string;
    clientQuestion: string;
    maxCharacters: number;
    placeholder: string;
    display: boolean;
    type: 'default' | 'vetSchool';
    questionnaireType: TeamDatabaseQuestionnaireType;
};

export type TeamDatabaseQuestionInput = Omit<
    TeamDatabaseQuestion,
    '_id' | 'orderId' | 'type' | 'questionnaireType'
> & {
    _id?: string;
};

export const FREEFORM_SETTINGS_ID = 'freeformBioSettings';

export type FreeformBioSettings = {
    _id: typeof FREEFORM_SETTINGS_ID;
    maxCharacters: number;
    placeholder: string;
};

export const SLACKBOT_SETTINGS_ID = 'slackbot';

export type SlackbotSettings = {
    _id: typeof SLACKBOT_SETTINGS_ID;
    slackbotUrl: string;
};

export const UPDATE_SETTINGS_ID = 'update';

export type UpdateSettings = {
    _id: typeof UPDATE_SETTINGS_ID;
    secondaryLocationsPastDays: number;
    secondaryLocationsFutureDays: number;
    secondaryLocationsMinShifts: number;
};

export const defaultUpdateSettings: UpdateSettings = {
    _id: UPDATE_SETTINGS_ID,
    secondaryLocationsPastDays: 30,
    secondaryLocationsFutureDays: 30,
    secondaryLocationsMinShifts: 3,
};

export type TeamDatabaseSettings =
    | FreeformBioSettings
    | UpdateSettings
    | SlackbotSettings;

export const PAGES = ['aboutUs', 'location'] as const;

export type Page = (typeof PAGES)[number];

export enum BioType {
    doctorQuestionnaire = 'doctorQuestionnaire',
    generalQuestionnaire = 'generalQuestionnaire',
    freeform = 'freeform',
}

export type TeamDatabaseRole = {
    _id: string;
    pages: ReadonlyArray<Page>;
    bioType: BioType;
    includePrefix: boolean;
};

export type SaveTeamDatabaseRoleResult = {
    saveTeamDatabaseRole: OperationResult;
};

export type SaveTeamDatabaseRoleVariables = {
    id: string;
    input: Omit<TeamDatabaseRole, '_id'>;
};

export type RemoveTeamDatabaseRoleResult = {
    removeTeamDatabaseRole: OperationResult;
};

export type RemoveTeamDatabaseRoleVariables = {
    id: string;
};

export type TeamDatabaseRolesResult = {
    teamDatabaseRoles: ReadonlyArray<TeamDatabaseRole>;
};

export type TeamDatabaseRoleVariables = {
    email: string;
};

export type TeamDatabaseRoleResult = {
    teamDatabaseRole: TeamDatabaseRole | null;
};

export type FreeformBioSettingsResult = {
    freeformBioSettings: Omit<FreeformBioSettings, '_id'>;
};

export type SetFreeformBioSettingsResult = {
    setFreeformBioSettings: OperationResult;
};

export type SetFreeformBioSettingsVariables = {
    input: Omit<FreeformBioSettings, '_id'>;
};

export type TeamDatabaseQuestionsResult = {
    teamDatabaseQuestions: ReadonlyArray<TeamDatabaseQuestion>;
};

export type SaveTeamDatabaseQuestionResult = {
    saveTeamDatabaseQuestion: OperationResult;
};

export type SaveTeamDatabaseQuestionVariables = {
    input: TeamDatabaseQuestionInput;
    questionnaires?: ReadonlyArray<TeamDatabaseQuestionnaireType>;
};

export type SaveTeamDatabaseQuestionOrderResult = {
    saveTeamDatabaseQuestionOrder: OperationResult;
};

export type SaveTeamDatabaseQuestionOrderVariables = {
    input: ReadonlyArray<Pick<TeamDatabaseQuestion, '_id' | 'orderId'>>;
};

export type NotifyNewBioSubmissionResult = {
    notifyNewBioSubmission: OperationResult;
};

export type NotifyNewBioSubmissionVariables = {
    name: string;
};
