import useLocationId from 'hooks/useLocationId';
import * as React from 'react';
import {
    GraphQLGeneralSurgeryReferralQuestion,
    SurgeryReferralAnswer,
    SurgeryReferralQuestion,
    SurgeryType,
    SingleValueAnswer,
    MultiValueAnswer,
    SINGLE_VALUE_ANSWER_TYPES,
    OptionsQuestion,
    OPTIONS_QUESTION_TYPES,
    SurgeryReferralInput,
    RecommendedDate,
    RECOMMENDED_DATE_EMERGENT,
    RECOMMENDED_SURGEON_NO_PREFERENCE,
    RECOMMENDED_SURGEON_MYSELF,
    PreferredClinic,
} from '@bondvet/types/surgeryReferrals';
import {
    SpecialtySurgeriesEnabledResult,
    SpecialtySurgerySettingsResult,
} from '@bondvet/types/booking';
import { gql, useMutation } from '@apollo/client';
import { OperationResult } from '@bondvet/types';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import useVetspireProviders from 'hooks/useVetspireProviders';
import useTranslate from 'hooks/useTranslate';
import useBookingQuery from 'hooks/useBookingQuery';
import useBookingMutation from 'hooks/useBookingMutation';
import { useAnalytics } from 'hooks/useAnalytics';
import { Page } from 'lib/vetspireActions';
import { getVetspireLocationId } from 'context/VetspireContext';
import useViewerSettings from 'hooks/useViewerSettings';
import { SendSurgeryReferralContext } from '../../context';
import useGeneralQuestions from '../../hooks/useGeneralQuestions';
import {
    AnswerData,
    OptionsAnswerData,
    SurgeonOption,
    CheckboxValues,
} from '../../types';
import {
    scheduleConsultationMutation,
    sendSurgeryRecommendation,
    SendSurgeryRecommendationResult,
    SendSurgeryRecommendationVariables,
} from '../../api/mutations';

interface FormData extends SendSurgeryReferralContext {
    answers: ReadonlyArray<AnswerData>;
    changeSurgeryType: (surgeryType: SurgeryType | null) => void;
    generalQuestions: ReadonlyArray<GraphQLGeneralSurgeryReferralQuestion>;
    recommendedSpecialtySurgeon: { id: string; name: string } | null;
    surgeonOptions: ReadonlyArray<SurgeonOption>;
    valid: boolean;
    submit: (event: React.FormEvent<HTMLFormElement>) => void;
    submitting: boolean;
    succeeded: boolean;
    loading: boolean;
    error: string | null;
    checkboxValues: CheckboxValues;
    changeCheckboxValues: (newValues: Partial<CheckboxValues>) => void;
    selectedConsultationTime: string | null;
    setSelectedConsultationTime: (time: string) => void;
    specialtySurgeryConsultationFee: number;
    specialtySurgeriesEnabled: boolean;
    resetForm: () => void;
}

const specialtySurgerySettingsQuery = gql`
    query specialtySurgerySettings($vetspireLocationId: String!) {
        specialtySurgerySettings(vetspireLocationId: $vetspireLocationId) {
            defaultVetspireProviderId
            consultationFee
        }
    }
`;

const specialtySurgeriesEnabledQuery = gql`
    query specialtySurgeriesEnabled($vetspireLocationId: String!) {
        specialtySurgeriesEnabled(vetspireLocationId: $vetspireLocationId)
    }
`;

function getAnswerId({ type, label }: SurgeryReferralQuestion): string {
    return `${type}-${label.replace(/[^\w]+/g, '-').toLowerCase()}`;
}

function validate(answers: ReadonlyArray<AnswerData>): boolean {
    return answers.reduce<boolean>((result, answer) => {
        if (answer.required) {
            if (SINGLE_VALUE_ANSWER_TYPES.includes(answer.type)) {
                const value = (answer as SingleValueAnswer).answer as string;

                return result && !!value?.trim();
            }

            const value = (answer as MultiValueAnswer).answers as string[];

            return result && value.length > 0;
        }

        return result;
    }, true);
}

function mapQuestion(
    question: SurgeryReferralQuestion,
    sortOrder: number,
): AnswerData | OptionsAnswerData {
    const { type, label, required } = question;
    const id = getAnswerId(question);
    const base: Omit<AnswerData | OptionsAnswerData, 'answer' | 'answers'> = {
        type,
        id,
        label,
        sortOrder,
        required,
        valid: !required,
    };

    if (OPTIONS_QUESTION_TYPES.includes(type)) {
        (base as OptionsAnswerData).options = (
            question as OptionsQuestion
        ).options;
    }

    if (SINGLE_VALUE_ANSWER_TYPES.includes(type)) {
        return {
            ...base,
            answer: '',
        } as AnswerData | OptionsAnswerData;
    }

    return {
        ...(base as Omit<OptionsAnswerData, 'answers'>),
        answers: [],
    } as OptionsAnswerData;
}

export default function useForm(
    clientId: string | null,
    patientId: string | null,
): FormData {
    const translate = useTranslate();
    const viewer = useViewerSettings();
    const locationId = useLocationId();

    const [preferredClinic, setPreferredClinic] =
        React.useState<PreferredClinic>(() => getVetspireLocationId());
    const [surgeryType, setSurgeryType] = React.useState<SurgeryType | null>(
        null,
    );
    const { questions: generalQuestions, loading: loadingGeneralQuestions } =
        useGeneralQuestions();
    const { loading: loadingVetspireProviders, vetspireProviders } =
        useVetspireProviders();
    const [recommendedSurgeonIds, setRecommendedSurgeonIds] = React.useState<
        string[]
    >([]);
    const [answers, setAnswers] = React.useState<ReadonlyArray<AnswerData>>([]);
    const [checkboxValues, setCheckboxValues] = React.useState({
        consultationOnly: false,
        fee: false,
    });
    const [selectedConsultationTime, setSelectedConsultationTime] =
        React.useState<string | null>(null);

    const surgeonOptions = React.useMemo<ReadonlyArray<SurgeonOption>>(() => {
        const canRecommendSelf =
            (!surgeryType?.isSpecialtySurgery && viewer.canDoSurgeries) ||
            (surgeryType?.isSpecialtySurgery && viewer.canDoSpecialtySurgeries);
        return [
            ...(!surgeryType?.isSpecialtySurgery
                ? [
                      {
                          value: RECOMMENDED_SURGEON_NO_PREFERENCE,
                          label: translate(
                              'vetspireExtension.surgeryReferrals.send.noPreference',
                          ) as string,
                      },
                  ]
                : []),
            ...(canRecommendSelf
                ? [
                      {
                          value: RECOMMENDED_SURGEON_MYSELF,
                          label: translate(
                              'vetspireExtension.surgeryReferrals.send.myself',
                          ) as string,
                      },
                  ]
                : []),
            ...vetspireProviders
                .filter(
                    (provider) =>
                        provider.canDoSurgeries &&
                        (!surgeryType?.isSpecialtySurgery ||
                            provider.canDoSpecialtySurgeries),
                )
                .map((provider) => ({
                    value: provider.id,
                    label: provider.name || '?',
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        ];
    }, [surgeryType, translate, vetspireProviders, viewer]);

    const { data: specialtySurgerySettingData } =
        useBookingQuery<SpecialtySurgerySettingsResult>(
            specialtySurgerySettingsQuery,
            {
                variables: {
                    vetspireLocationId: preferredClinic,
                },
            },
        );

    const { data: specialtySurgeriesEnabledData } =
        useBookingQuery<SpecialtySurgeriesEnabledResult>(
            specialtySurgeriesEnabledQuery,
            {
                variables: {
                    vetspireLocationId: locationId,
                },
            },
        );

    const specialtySurgeriesEnabled =
        specialtySurgeriesEnabledData?.specialtySurgeriesEnabled ?? false;

    React.useEffect(() => {
        if (
            specialtySurgerySettingData?.specialtySurgerySettings
                ?.defaultVetspireProviderId &&
            surgeryType?.isSpecialtySurgery
        ) {
            setRecommendedSurgeonIds([
                specialtySurgerySettingData?.specialtySurgerySettings
                    ?.defaultVetspireProviderId,
            ]);
        } else if (!surgeryType?.isSpecialtySurgery) {
            setRecommendedSurgeonIds([]);
        }
    }, [specialtySurgerySettingData, surgeryType?.isSpecialtySurgery]);

    const onChange = React.useCallback(
        (
            id: string,
            type: SurgeryReferralAnswer['type'],
            value: string | string[],
        ) => {
            setAnswers((oldAnswers) => {
                const idx = oldAnswers.findIndex(
                    (oldAnswer) => oldAnswer.id === id,
                );

                if (idx === -1) {
                    throw new Error(`cannot find answer ${id}`);
                }

                const answer = {
                    ...oldAnswers[idx],
                };

                if (SINGLE_VALUE_ANSWER_TYPES.includes(type)) {
                    (answer as SingleValueAnswer).answer = value as string;
                    answer.valid =
                        !answer.required || !!(value as string)?.trim();
                } else {
                    (answer as MultiValueAnswer).answers = value as string[];
                    answer.valid =
                        !answer.required || (value as string[]).length > 0;
                }

                return [
                    ...oldAnswers.slice(0, idx),
                    answer,
                    ...oldAnswers.slice(idx + 1),
                ];
            });
        },
        [],
    );

    React.useEffect(() => {
        const allQuestions = [
            ...generalQuestions.map(({ question }) => question),
            ...(surgeryType?.questions || []),
        ];

        setAnswers(
            allQuestions.map<AnswerData>((question, idx) =>
                mapQuestion(question, idx),
            ),
        );
    }, [surgeryType?.questions, generalQuestions]);

    const [recommendForEducationalFund, setRecommendForEducationalFund] =
        React.useState(false);
    const [notes, setNotes] = React.useState('');

    const changeNotes = React.useCallback((newNotes: string | null) => {
        setNotes(newNotes || '');
    }, []);

    const changeRecommendForEducationalFund = React.useCallback(
        (newValue: boolean) => {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    setRecommendForEducationalFund(newValue);
                });
            });
        },
        [],
    );

    const [recommendedDate, setRecommendedDate] =
        React.useState<RecommendedDate>(RECOMMENDED_DATE_EMERGENT);

    const changeRecommendedDate = React.useCallback(
        (newValue: RecommendedDate) => {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    setRecommendedDate(newValue);
                });
            });
        },
        [],
    );

    const changeSurgeryType = React.useCallback(
        (type: SurgeryType | null) => {
            if (type?.isSpecialtySurgery && recommendedSurgeonIds.length > 1) {
                setRecommendedSurgeonIds([]);
            }
            setSurgeryType(type);
        },
        [recommendedSurgeonIds],
    );

    const changePreferredClinic = React.useCallback(
        (newValue: PreferredClinic) => {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    setPreferredClinic(newValue);
                });
            });
        },
        [],
    );

    const changeRecommendedSurgeonIds = React.useCallback(
        (newValues: string[]) => {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    setRecommendedSurgeonIds(newValues);
                });
            });
        },
        [],
    );

    const changeCheckboxValues = React.useCallback(
        (newValues: Partial<CheckboxValues>) => {
            setCheckboxValues((prev) => ({ ...prev, ...newValues }));
        },
        [],
    );

    const recommendedSpecialtySurgeon = React.useMemo(() => {
        if (
            surgeryType?.isSpecialtySurgery &&
            recommendedSurgeonIds.length === 1
        ) {
            if (recommendedSurgeonIds[0] === RECOMMENDED_SURGEON_MYSELF) {
                return {
                    id: viewer.viewer?.id ?? '',
                    name: 'me',
                };
            }

            const provider = vetspireProviders.find(
                ({ id }) => id === recommendedSurgeonIds[0],
            );

            return provider
                ? {
                      id: provider.id,
                      name: provider.name ?? 'Unnamed Provider',
                  }
                : null;
        }

        return null;
    }, [recommendedSurgeonIds, surgeryType, vetspireProviders, viewer]);

    const valid = React.useMemo(
        () =>
            (!!notes || !surgeryType?.notesRequired) &&
            recommendedSurgeonIds.length > 0 &&
            validate(answers) &&
            (!surgeryType?.isSpecialtySurgery ||
                (checkboxValues.consultationOnly &&
                    checkboxValues.fee &&
                    !!selectedConsultationTime)),
        [
            checkboxValues,
            answers,
            notes,
            recommendedSurgeonIds.length,
            selectedConsultationTime,
            surgeryType,
        ],
    );

    const [runMutation, { loading: submitting }] = useMutation<
        SendSurgeryRecommendationResult,
        SendSurgeryRecommendationVariables
    >(sendSurgeryRecommendation, {
        context: {
            clientName: GRAPHQL_CLIENT_NAMES.default,
        },
    });

    const [runScheduleConsultation, { loading: scheduleConsultationLoading }] =
        useBookingMutation<{ createConsultationAppointment: OperationResult }>(
            scheduleConsultationMutation,
        );

    const analytics = useAnalytics();
    const [succeeded, setSucceeded] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);

    const submit = React.useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (surgeryType && clientId && patientId) {
                const input: SurgeryReferralInput = {
                    surgeryTypeId: surgeryType._id,
                    clientId,
                    patientId,
                    locationId,
                    recommendForEducationalFund,
                    notes,
                    recommendedDate,
                    preferredClinic,
                    recommendedSurgeonIds,
                    answers: answers.map((answer) => {
                        const { type, label } = answer;
                        const base: Pick<
                            SurgeryReferralAnswer,
                            'label' | 'type'
                        > = {
                            label,
                            type,
                        };

                        if (SINGLE_VALUE_ANSWER_TYPES.includes(type)) {
                            return {
                                ...base,
                                answer: (answer as SingleValueAnswer).answer,
                            } as SingleValueAnswer;
                        }

                        return {
                            ...base,
                            answers: (answer as MultiValueAnswer).answers,
                        } as MultiValueAnswer;
                    }),
                };

                const { data: surgeryRecommendationResult } = await runMutation(
                    {
                        variables: {
                            input,
                        },
                    },
                );

                if (surgeryRecommendationResult?.sendSurgeryRecommendation) {
                    const { success, error: submissionError } =
                        surgeryRecommendationResult.sendSurgeryRecommendation;

                    if (success) {
                        analytics.trackEvent(
                            Page.surgeryReferrals,
                            'send_my_surgery_recommendation',
                        );

                        if (surgeryType.isSpecialtySurgery) {
                            const apptNotes = `${surgeryType.name} Consultation${notes ? `\n${notes}` : ''}`;

                            const { data: scheduleConsultationResult } =
                                await runScheduleConsultation({
                                    variables: {
                                        input: {
                                            vetspireClientId: clientId,
                                            vetspirePatientId: patientId,
                                            vetspireLocationId: locationId,
                                            vetspireProviderId:
                                                recommendedSurgeonIds[0],
                                            time: selectedConsultationTime,
                                            notes: apptNotes,
                                        },
                                    },
                                });

                            if (
                                scheduleConsultationResult
                                    ?.createConsultationAppointment?.success
                            ) {
                                setSucceeded(true);
                            } else {
                                setError(
                                    scheduleConsultationResult
                                        ?.createConsultationAppointment
                                        ?.error ??
                                        'Error while scheduling an appointment!',
                                );
                            }
                        } else {
                            setSucceeded(true);
                        }
                    } else if (submissionError) {
                        setError(submissionError);
                    }
                }
            }
        },
        [
            surgeryType,
            clientId,
            patientId,
            locationId,
            recommendForEducationalFund,
            notes,
            recommendedDate,
            preferredClinic,
            recommendedSurgeonIds,
            answers,
            runMutation,
            analytics,
            runScheduleConsultation,
            selectedConsultationTime,
        ],
    );

    const resetForm = React.useCallback(() => {
        setSucceeded(false);
        setError(null);
        setSurgeryType(null);
        const defaultPreferredClinic = getVetspireLocationId();
        setPreferredClinic(defaultPreferredClinic);
        setRecommendedSurgeonIds([]);
        setAnswers([]);
        setCheckboxValues({ consultationOnly: false, fee: false });
        setSelectedConsultationTime(null);
        setRecommendForEducationalFund(false);
        setNotes('');
        setRecommendedDate(RECOMMENDED_DATE_EMERGENT);
    }, []);

    React.useEffect(() => {
        if (submitting) {
            setSucceeded(false);
            setError(null);
        }
    }, [submitting]);

    return {
        surgeryType,
        notes,
        recommendForEducationalFund,
        answers,
        changeSurgeryType,
        changeNotes,
        changeRecommendForEducationalFund,
        recommendedSpecialtySurgeon,
        generalQuestions,
        surgeonOptions,
        onChange,
        valid,
        submitting: submitting || scheduleConsultationLoading,
        submit,
        succeeded,
        loading: loadingGeneralQuestions || loadingVetspireProviders,
        error,
        recommendedDate,
        changeRecommendedDate,
        preferredClinic,
        changePreferredClinic,
        recommendedSurgeonIds,
        changeRecommendedSurgeonIds,
        checkboxValues,
        changeCheckboxValues,
        selectedConsultationTime,
        setSelectedConsultationTime,
        specialtySurgeryConsultationFee:
            specialtySurgerySettingData?.specialtySurgerySettings
                ?.consultationFee ?? 0,
        specialtySurgeriesEnabled,
        resetForm,
    };
}
